<template>
  <BaseLayout>
    <div class="flex justify-center items-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-300">
      <div
        class="bg-white bg-opacity-80 backdrop-filter backdrop-blur-3xl p-8 rounded-3xl shadow-2xl text-gray-900 w-full max-w-5xl">
        <h2 class="text-4xl font-bold text-center mb-6 text-gray-800">Bliv Partner med Freshcuts</h2>
        <p class="text-lg text-center mb-10 text-gray-600">Boost din forretning med vores eksklusive
          partnerskabsprogram.</p>

        <div class="flex flex-col lg:flex-row gap-6 justify-center">
          <!-- Freshcuts Partner -->
          <div class="bg-white bg-opacity-70 p-6 rounded-3xl shadow-lg flex-1">
            <h3 class="text-2xl font-semibold mb-4 text-gray-700">🚀 Freshcuts Partner</h3>
            <p class="text-lg mb-4 text-gray-600">Pris: <span class="font-bold">99,- / md.</span></p>
            <ul class="text-md list-disc list-inside text-gray-700 space-y-2">
              <li class="flex items-start space-x-2">
                <span>💼</span>
                <span>Få flere kunder hver måned!</span>
              </li>
              <li class="flex items-start space-x-2">
                <span>🤝</span>
                <span>Få din egen profil på platformen.</span>
              </li>
              <li class="flex items-start space-x-2">
                <span>📅</span>
                <span>Nem og hurtig online booking.</span>
              </li>
            </ul>
          </div>

          <!-- Coming Soon -->
          <div class="bg-gray-200 p-6 rounded-3xl shadow-lg flex-1">
            <h4 class="text-2xl font-semibold mb-4 text-gray-700">🚧 Freshcuts Partner - Månedens Frisør</h4>
            <p class="text-lg mb-4 text-gray-600">Pris: <span class="font-bold">499,- / md.</span></p>
            <ul class="text-md list-disc list-inside text-gray-700 space-y-2">
              <li>Bliv præsenteret som månedens frisør</li>
              <li>Få ekstra synlighed på forsiden</li>
              <li>Flere kunder og ingen startomkostninger</li>
            </ul>
            <p class="mt-6 text-center text-gray-500">Kommer snart...</p>
          </div>
        </div>

        <!-- Registering Form -->
        <div class="bg-white bg-opacity-70 p-8 rounded-3xl shadow-lg mt-10">
          <h3 class="text-2xl font-bold text-center mb-6 text-gray-800">Registrer dig som Partner</h3>
          <form @submit.prevent="register('partner')" class="space-y-6">

            <!-- Virksomhedstype -->
            <div>
              <label for="businessType" class="block text-lg font-semibold text-gray-700">Virksomhedstype</label>
              <select id="businessType" v-model="businessType"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
                <option disabled value="">Vælg type</option>
                <option value="salon">💈 Frisør</option>
                <option value="klinik">💅 Klinik</option>
              </select>
            </div>


            <!-- Navn -->
            <div>
              <label for="name" class="block text-lg font-semibold text-gray-700">Navn</label>
              <input type="text" id="name" v-model="name" placeholder="Salon Navn"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required />
            </div>

            <!-- Beskrivelse -->
            <div>
              <label for="description" class="block text-lg font-semibold text-gray-700">Beskrivelse</label>
              <textarea id="description" v-model="description"
                placeholder="Beskriv din salon (f.eks. specialer, erfaringer)"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" rows="4"></textarea>
            </div>


            <!-- Email -->
            <div>
              <label for="email" class="block text-lg font-semibold text-gray-700">Email</label>
              <input type="email" id="email" v-model="email" placeholder="email@example.com"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required />
            </div>

            <!-- Password -->
            <div>
              <label for="password" class="block text-lg font-semibold text-gray-700">Password</label>
              <input type="password" id="password" v-model="password" placeholder="Vælg et sikkert kodeord"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required />
            </div>

            <!-- Adresse -->
            <div>
              <label for="location" class="block text-lg font-semibold text-gray-700">Adresse</label>
              <input type="text" id="location" v-model="location" placeholder="F.eks. Nørrebrogade 110, 2200 København"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required />
            </div>

            <!-- Telefon -->
            <div>
              <label for="phone" class="block text-lg font-semibold text-gray-700">Telefon</label>
              <input type="tel" id="phone" v-model="phone" placeholder="+45 35353370"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required />
            </div>

            <!-- Upload billeder af salonen -->
            <div>
              <label for="salonImages" class="block text-lg font-semibold text-gray-700">Upload billeder af
                salonen</label>
              <input type="file" id="salonImages" @change="onFileChange" multiple
                class="w-full p-3 border border-gray-300 rounded-full">
            </div>

            <!-- Åbningstider -->
            <div>
              <label for="weekdays_open" class="block text-lg font-semibold text-gray-700">Hverdage Åbningstider</label>
              <div class="flex space-x-2">
                <input type="time" id="weekdays_open" v-model="openingHours.weekdays.open"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
                <input type="time" id="weekdays_close" v-model="openingHours.weekdays.close"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
              </div>
            </div>
            <div>
              <label for="saturday_open" class="block text-lg font-semibold text-gray-700">Lørdag Åbningstider</label>
              <div class="flex space-x-2">
                <input type="time" id="saturday_open" v-model="openingHours.saturday.open"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
                <input type="time" id="saturday_close" v-model="openingHours.saturday.close"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
              </div>
            </div>
            <div>
              <label for="sunday_open" class="block text-lg font-semibold text-gray-700">Søndag Åbningstider</label>
              <div class="flex space-x-2">
                <input type="time" id="sunday_open" v-model="openingHours.sunday.open"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900">
                <input type="time" id="sunday_close" v-model="openingHours.sunday.close"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900">
              </div>
            </div>

            <!-- Søgefelt og overordnede kategorier -->
            <div class="relative mb-6">
              <input v-model="serviceSearchQuery" type="text" placeholder="Søg efter tjenester..."
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900 focus:border-blue-400 transition-colors duration-300" />
              <svg class="absolute right-4 top-3 h-6 w-6 text-gray-400" fill="none" stroke="currentColor"
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>

            <!-- Kategorier med foldbare sektioner -->
            <div class="space-y-6">
              <!-- Kategori 1: Hårpleje og Klip -->
              <div @click="toggleCategory('hairCare')"
                class="cursor-pointer p-4 rounded-lg shadow-md bg-blue-100 hover:bg-blue-200 transition-all duration-300">
                <div class="flex justify-between items-center">
                  <h3 class="text-lg font-semibold text-gray-700">💇 Hårpleje og Klip</h3>
                  <span v-if="!categoriesOpen.hairCare" class="text-gray-500">➕</span>
                  <span v-if="categoriesOpen.hairCare" class="text-gray-500">➖</span>
                </div>
                <transition name="fade">
                  <div v-if="categoriesOpen.hairCare" class="mt-4 space-y-4">
                    <div v-for="(service, index) in filteredServicesByCategory('hairCare')" :key="index"
                      class="border border-gray-200 rounded-lg p-4 flex items-center justify-between hover:shadow-md transition-shadow duration-300 cursor-pointer"
                      @click="toggleService(service)">
                      <div class="flex items-center space-x-4">
                        <input type="checkbox" v-model="service.selected"
                          class="form-checkbox h-5 w-5 text-blue-500 transition-all duration-300" />
                        <span class="text-lg font-medium text-gray-700">{{ service.name }}</span>
                      </div>
                      <span class="text-gray-500">Standard Pris: {{ service.defaultPrice }}</span>
                    </div>
                  </div>
                </transition>
              </div>

              <!-- Kategori 2: Neglepleje -->
              <div @click="toggleCategory('nailCare')"
                class="cursor-pointer p-4 rounded-lg shadow-md bg-pink-100 hover:bg-pink-200 transition-all duration-300">
                <div class="flex justify-between items-center">
                  <h3 class="text-lg font-semibold text-gray-700">💅 Neglepleje</h3>
                  <span v-if="!categoriesOpen.nailCare" class="text-gray-500">➕</span>
                  <span v-if="categoriesOpen.nailCare" class="text-gray-500">➖</span>
                </div>
                <transition name="fade">
                  <div v-if="categoriesOpen.nailCare" class="mt-4 space-y-4">
                    <div v-for="(service, index) in filteredServicesByCategory('nailCare')" :key="index"
                      class="border border-gray-200 rounded-lg p-4 flex items-center justify-between hover:shadow-md transition-shadow duration-300 cursor-pointer"
                      @click="toggleService(service)">
                      <div class="flex items-center space-x-4">
                        <input type="checkbox" v-model="service.selected"
                          class="form-checkbox h-5 w-5 text-pink-500 transition-all duration-300" />
                        <span class="text-lg font-medium text-gray-700">{{ service.name }}</span>
                      </div>
                      <span class="text-gray-500">Standard Pris: {{ service.defaultPrice }}</span>
                    </div>
                  </div>
                </transition>
              </div>

              <!-- Kategori 3: Ansigts- og Kropsbehandlinger -->
              <div @click="toggleCategory('facialBody')"
                class="cursor-pointer p-4 rounded-lg shadow-md bg-green-100 hover:bg-green-200 transition-all duration-300">
                <div class="flex justify-between items-center">
                  <h3 class="text-lg font-semibold text-gray-700">🧖 Ansigts- og Kropsbehandlinger</h3>
                  <span v-if="!categoriesOpen.facialBody" class="text-gray-500">➕</span>
                  <span v-if="categoriesOpen.facialBody" class="text-gray-500">➖</span>
                </div>
                <transition name="fade">
                  <div v-if="categoriesOpen.facialBody" class="mt-4 space-y-4">
                    <div v-for="(service, index) in filteredServicesByCategory('facialBody')" :key="index"
                      class="border border-gray-200 rounded-lg p-4 flex items-center justify-between hover:shadow-md transition-shadow duration-300 cursor-pointer"
                      @click="toggleService(service)">
                      <div class="flex items-center space-x-4">
                        <input type="checkbox" v-model="service.selected"
                          class="form-checkbox h-5 w-5 text-green-500 transition-all duration-300" />
                        <span class="text-lg font-medium text-gray-700">{{ service.name }}</span>
                      </div>
                      <span class="text-gray-500">Standard Pris: {{ service.defaultPrice }}</span>
                    </div>
                  </div>
                </transition>
              </div>

              <!-- Kategori 4: Makeup og Styling -->
              <div @click="toggleCategory('makeupStyling')"
                class="cursor-pointer p-4 rounded-lg shadow-md bg-purple-100 hover:bg-purple-200 transition-all duration-300">
                <div class="flex justify-between items-center">
                  <h3 class="text-lg font-semibold text-gray-700">👰 Makeup og Styling</h3>
                  <span v-if="!categoriesOpen.makeupStyling" class="text-gray-500">➕</span>
                  <span v-if="categoriesOpen.makeupStyling" class="text-gray-500">➖</span>
                </div>
                <transition name="fade">
                  <div v-if="categoriesOpen.makeupStyling" class="mt-4 space-y-4">
                    <div v-for="(service, index) in filteredServicesByCategory('makeupStyling')" :key="index"
                      class="border border-gray-200 rounded-lg p-4 flex items-center justify-between hover:shadow-md transition-shadow duration-300 cursor-pointer"
                      @click="toggleService(service)">
                      <div class="flex items-center space-x-4">
                        <input type="checkbox" v-model="service.selected"
                          class="form-checkbox h-5 w-5 text-purple-500 transition-all duration-300" />
                        <span class="text-lg font-medium text-gray-700">{{ service.name }}</span>
                      </div>
                      <span class="text-gray-500">Standard Pris: {{ service.defaultPrice }}</span>
                    </div>
                  </div>
                </transition>
              </div>
            </div>

            <!-- Oversigt over valgte services og mulighed for at ændre pris -->
            <div class="bg-gray-50 p-6 rounded-lg shadow-md mt-8">
              <h3 class="text-2xl font-bold text-gray-800 mb-4">Valgte Tjenester</h3>

              <!-- Hvis der er nogen valgte tjenester -->
              <div v-if="selectedServices.length > 0" class="space-y-4">
                <div v-for="(service, index) in selectedServices" :key="index"
                  class="flex justify-between items-center p-4 bg-white rounded-lg shadow">
                  <div class="flex-1">
                    <h4 class="text-lg font-medium text-gray-700">{{ service.name }}</h4>
                    <input type="number" v-model="service.price" class="mt-2 p-2 border rounded-lg w-28"
                      placeholder="Ny pris" />
                  </div>
                  <button @click="removeService(index)"
                    class="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg">
                    Fjern
                  </button>
                </div>
              </div>

              <!-- Hvis ingen tjenester er valgt -->
              <p v-else class="text-gray-600">Ingen tjenester valgt endnu.</p>
            </div>

            <!-- Sektion til at tilføje specielle tjenester -->
            <div class="bg-gray-50 p-6 rounded-lg shadow-md mt-8">
              <h3 class="text-2xl font-bold text-gray-800 mb-4">Tilføj Special Service</h3>

              <div class="flex space-x-4">
                <input v-model="newService.name" type="text" class="w-1/2 p-3 border rounded-lg"
                  placeholder="Service navn" />
                <input v-model="newService.price" type="number" class="w-1/4 p-3 border rounded-lg"
                  placeholder="Pris" />
                <button @click="addSpecialService"
                  class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                  Tilføj
                </button>
              </div>
            </div>

            <button type="submit"
              class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition-all duration-300">Register</button>
          </form>

          <div class="text-center mt-6">
            <h3 class="text-lg font-semibold mb-4 text-gray-800">Trin 2: Betal for Aktivering</h3>
            <p class="text-md mb-4 text-gray-600">For at fuldføre din registrering, skal du betale for aktiveringen af
              din frisørsalon.</p>
            <a href="https://buy.stripe.com/9AQ8yWd9EgMlav628f" target="_blank"
              class="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-full transition-all duration-300">Betal
              nu</a>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "@/firebase"; // Firebase storage import
import BaseLayout from '../components/BaseLayout.vue';
import { getDocs, collection } from "firebase/firestore"; // For fetching universal services
55.757940
12.440400
export default {
  name: 'RegisterPage',
  components: {
    BaseLayout,
  },
  data() {
    return {
      email: '',
      password: '',
      name: '',
      description: '',
      location: '',
      phone: '',
      businessType: '',
      openingHours: {
        weekdays: { open: '08:00', close: '18:00' },
        saturday: { open: '08:00', close: '16:00' },
        sunday: { open: '', close: '' },
      },
      services: [], // For custom services
      universalServices: [], // For universal services hentet fra Firebase
      serviceSearchQuery: '', // Søgefelt for tjenester
      groupedServices: [], // For at gruppere services baseret på kategorier
      categoriesOpen: {
        hairCare: false,
        nailCare: false,
        facialBody: false,
        makeupStyling: false,
      }, // Kontroller for foldbare sektioner
      selectedServices: [], // For valgte tjenester
      newService: { name: '', price: '' }, // For special services
      selectedFiles: null, // For salon images
      error: null,
      showForm: false,
    };
  },
  computed: {
    // Computed property til at filtrere tjenesterne baseret på søgning
    filteredServices() {
      return this.universalServices.filter(service =>
        service.name.toLowerCase().includes(this.serviceSearchQuery.toLowerCase())
      );
    },
  },
  methods: {
    onFileChange(event) {
      this.selectedFiles = event.target.files;
    },
    async uploadImages() {
      const imageUrls = [];
      if (this.selectedFiles) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          const file = this.selectedFiles[i];
          const storageRef = ref(storage, `salon_images/${file.name}`);
          const snapshot = await uploadBytes(storageRef, file);
          const downloadUrl = await getDownloadURL(snapshot.ref);
          imageUrls.push(downloadUrl);
        }
      }
      return imageUrls;
    },

    // Geocode address using Nominatim (OpenStreetMap)
    async geocodeAddressNominatim() {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(this.location)}&format=json&limit=1`
        );
        const data = await response.json();

        if (data.length > 0) {
          return { lat: data[0].lat, lon: data[0].lon };
        } else {
          throw new Error("Unable to geocode address using Nominatim");
        }
      } catch (error) {
        console.error("Nominatim geocoding error:", error);
        return null;
      }
    },

    // Geocode address using Dataforsyningens API (For Danish addresses)
    async geocodeAddressDataforsyning() {
      try {
        const response = await fetch(
          `https://api.dataforsyningen.dk/adresser?q=${encodeURIComponent(this.location)}&format=json`
        );
        const data = await response.json();

        if (data.length > 0) {
          return { lat: data[0].adgangsadresse.adgangspunkt.koordinater[1], lon: data[0].adgangsadresse.adgangspunkt.koordinater[0] };
        } else {
          throw new Error("Unable to geocode address using Dataforsyningen");
        }
      } catch (error) {
        console.error("Dataforsyning geocoding error:", error);
        return null;
      }
    },

    async register(role) {
      try {
        this.error = null;
        const auth = getAuth();
        const db = getFirestore();

        // Geocode the address using Dataforsyning or fallback to Nominatim
        let geocodedLocation = await this.geocodeAddressDataforsyning();
        if (!geocodedLocation) {
          geocodedLocation = await this.geocodeAddressNominatim();
        }

        if (!geocodedLocation) {
          this.error = 'Kunne ikke finde koordinater for adressen';
          return;
        }

        // **NYT: Tjek om der er valgt tjenester**
        if (this.selectedServices.length === 0) {
          this.error = 'Du skal vælge mindst én service.';
          return;  // Stop registreringen, hvis ingen tjenester er valgt
        }

        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Upload images if any
        const imageUrls = await this.uploadImages();

        // Filter selected services with price
        const selectedServices = this.selectedServices.map(service => ({
          name: service.name,
          price: service.price || service.defaultPrice // Hvis der ikke er angivet en ny pris, brug standardpris
        }));

        // Add user to Firestore
        await setDoc(doc(db, 'users', user.uid), {
          name: this.name,
          email: this.email,
          role: role,
          createdAt: new Date(),
        });

        // Add salon to Firestore
        await setDoc(doc(db, 'salons', user.uid), {
          name: this.name,
          description: this.description,
          location: this.location,
          lat: geocodedLocation.lat,    // Store latitude
          lon: geocodedLocation.lon,    // Store longitude
          phone: this.phone,
          businessType: this.businessType,
          openingHours: this.openingHours,
          services: selectedServices, // Only add services with prices
          images: imageUrls, // Save the image URLs here
          createdAt: new Date(),
        });

        this.$router.push('/dashboard');
      } catch (error) {
        console.error("Error registering user: ", error);
        this.error = 'Der opstod en fejl. Prøv igen senere.';
      }
    }
    ,
    addService() {
      this.services.push({ name: '', price: '' });
    },
    async fetchUniversalServices() {
      try {
        const db = getFirestore();
        const servicesSnapshot = await getDocs(collection(db, "universalServices"));
        this.universalServices = servicesSnapshot.docs.map(doc => doc.data().services).flat();

        // Ingen filtrering først, hent alt og filtrér efter virksomhedstype senere
        this.groupedServices = [{ name: 'Alle Tjenester', services: this.universalServices }];
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
    },
    filteredServicesByCategory(category) {
      const categoryMap = {
        hairCare: ['klip', 'hår'],
        nailCare: ['negle', 'manicure'],
        facialBody: ['ansigt', 'massage', 'voks'],
        makeupStyling: ['makeup', 'brude'],
      };

      const terms = categoryMap[category];
      return this.universalServices.filter(service =>
        terms.some(term => service.name.toLowerCase().includes(term))
      );
    },
    toggleCategory(category) {
      this.categoriesOpen[category] = !this.categoriesOpen[category];
    },
    filterServicesByBusinessType() {
      if (this.businessType === 'salon') {
        this.groupedServices = [{
          name: 'Frisør Tjenester',
          services: this.universalServices.filter(service => service.name.toLowerCase().includes("klip") || service.name.toLowerCase().includes("hår"))
        }];
      } else if (this.businessType === 'klinik') {
        this.groupedServices = [{
          name: 'Klinik Tjenester',
          services: this.universalServices.filter(service => service.name.toLowerCase().includes("negle") || service.name.toLowerCase().includes("ansigt") || service.name.toLowerCase().includes("voks"))
        }];
      }
    },
    toggleService(service) {
      const selectedIndex = this.selectedServices.findIndex(s => s.name === service.name);

      if (selectedIndex > -1) {
        this.selectedServices.splice(selectedIndex, 1); // Fjern tjenesten, hvis den allerede er valgt
      } else {
        this.selectedServices.push({ name: service.name, price: service.defaultPrice || 0 }); // Tilføj tjenesten
      }
    },
    removeService(index) {
      this.selectedServices.splice(index, 1);
    },
    addSpecialService() {
      if (this.newService.name && this.newService.price) {
        this.selectedServices.push({ name: this.newService.name, price: this.newService.price });
        this.newService = { name: '', price: '' };
      } else {
        alert('Udfyld venligst både navn og pris for den specielle service.');
      }
    }
  },
  async mounted() {
    await this.fetchUniversalServices();
  },
  watch: {
    businessType(newType) {
      if (newType) {
        this.filterServicesByBusinessType();
      }
    }
  },
};



</script>


<style scoped>
/* General Layout Styles */
.bg-opacity-60 {
  background-opacity: 0.6;
}

.backdrop-filter {
  backdrop-filter: blur(15px);
}

.backdrop-blur-3xl {
  backdrop-filter: blur(30px);
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.shadow-2xl {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
}

/* Typography and Text Alignment */
.text-center {
  text-align: center;
}

.text-gray-900 {
  color: #1a202c;
}

.text-lg {
  font-size: 1.125rem;
}

.text-md {
  font-size: 1rem;
}

.text-4xl {
  font-size: 2.25rem;
}

/* Margin and Padding */
.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.space-y-4>*+* {
  margin-top: 1rem;
}

.space-x-2>*+* {
  margin-left: 0.5rem;
}

.space-y-2>*+* {
  margin-top: 0.5rem;
}

.space-x-6>*+* {
  margin-left: 1.5rem;
}

/* Form elements */
input,
textarea,
select {
  width: 100%;
  padding: 0.875rem;
  border: 1px solid #d2d6dc;
  border-radius: 9999px;
  background-color: #fff;
  color: #1a202c;
  transition: border-color 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #63b3ed;
  outline: none;
}

input[type="file"] {
  padding: 0.75rem;
  border-radius: 9999px;
  border: 1px solid #d2d6dc;
  background-color: #f7fafc;
}

/* Buttons */
button {
  padding: 0.875rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:focus {
  outline: none;
}

/* Button Colors */
.bg-green-500 {
  background-color: #48bb78;
}

.bg-green-500:hover {
  background-color: #38a169;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.bg-blue-500:hover {
  background-color: #3182ce;
}

.bg-red-500 {
  background-color: #f56565;
}

.bg-red-500:hover {
  background-color: #e53e3e;
}

/* Transitions and Animations */
.transition {
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .text-lg {
    font-size: 1rem;
  }

  .text-4xl {
    font-size: 1.75rem;
  }

  .p-4 {
    padding: 1rem;
  }

  .p-6 {
    padding: 1.25rem;
  }

  .rounded-lg {
    border-radius: 1rem;
  }

  .rounded-3xl {
    border-radius: 1.25rem;
  }

  .space-y-4>*+* {
    margin-top: 0.75rem;
  }

  input,
  select,
  button {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .mt-4,
  .mt-6 {
    margin-top: 1.25rem;
  }
}

@media (min-width: 769px) {
  .max-w-lg {
    max-width: 36rem;
  }

  .max-w-5xl {
    max-width: 90rem;
  }

  .p-8 {
    padding: 2rem;
  }

  .rounded-3xl {
    border-radius: 1.75rem;
  }

  input,
  button {
    padding: 1rem;
  }
}
</style>
