<template>
    <BaseLayout>
        <div class="salon-profile">
            <button @click="$router.go(-1)" class="back-button">← Tilbage</button>

            <!-- Salon Header with details -->
            <div class="profile-header">
                <h1>{{ salon.name }}</h1>
                <p>{{ salon.location }}</p>
                <p>{{ salon.phone }}</p>

                <div v-if="salon.openingHours">
                    <p v-for="(times, day) in salon.openingHours" :key="day">
                        {{ day }}: {{ times.open || 'N/A' }} - {{ times.close || 'N/A' }}
                    </p>
                </div>
                <a :href="salon.website" target="_blank">{{ salon.website }}</a>
            </div>

            <!-- Display available services dynamically -->
            <div class="services">
                <h2>Tjenester</h2>
                <ul>
                    <li v-for="(service, index) in salon.services" :key="index">
                        {{ service.name }} - {{ service.price }} DKK
                    </li>
                </ul>
            </div>

            <!-- Reviews Section -->
            <div class="reviews">
                <h2>Anmeldelser</h2>
                <div v-for="review in reviews" :key="review.id" class="review-item">
                    <div class="review-box">
                        <p class="review-user">{{ review.user }}</p>
                        <p class="review-comment">{{ review.comment }}</p>
                        <img v-if="review.image" :src="review.image" alt="Anmeldelsesbillede" class="review-image" />
                        <div class="like-dislike-section">
                            <button @click="likeReview(review.id)" class="like-btn" :disabled="hasVoted">👍</button>
                            <span>{{ review.likes }}</span>
                            <button @click="dislikeReview(review.id)" class="dislike-btn" :disabled="hasVoted">👎</button>
                            <span>{{ review.dislikes }}</span>
                        </div>
                    </div>
                </div>

                <!-- Submit Review Form -->
                <form @submit.prevent="submitReview" class="submit-review-form">
                    <textarea v-model="newReview.comment" placeholder="Skriv en anmeldelse"></textarea>
                    <input type="file" @change="uploadImage" />
                    <button type="submit">Indsend anmeldelse</button>
                </form>
            </div>

            <!-- Display salon's overall rating -->
            <div class="ratings">
                <h2>Samlet bedømmelse af salonen:</h2>
                <div class="like-bar">
                    <div class="like-count">{{ salon.totalLikes }}</div>
                    <div class="like-meter" :style="{ width: salonRating + '%' }"></div>
                    <div class="dislike-count">{{ salon.totalDislikes }}</div>
                </div>
                <button @click="likeSalon" class="like-btn" :disabled="hasVotedSalon">👍 Like Salonen</button>
                <button @click="dislikeSalon" class="dislike-btn" :disabled="hasVotedSalon">👎 Dislike Salonen</button>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
import { db, storage } from "../firebase"; // Firebase configuration
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { doc, getDoc, updateDoc, addDoc, collection, getDocs } from "firebase/firestore";
import BaseLayout from '../components/BaseLayout.vue';
import axios from 'axios';  // Import axios to get IP address

export default {
    components: {
        BaseLayout, // Register the BaseLayout component
    },
    data() {
        return {
            salon: {},
            reviews: [],
            newReview: {
                comment: "",
                image: "",
            },
            userIP: null,  // Store user IP address
            hasVoted: false,  // Track if the user has voted on reviews
            hasVotedSalon: false,  // Track if the user has voted on the salon
        };
    },
    computed: {
        salonRating() {
            const totalVotes = this.salon.totalLikes + this.salon.totalDislikes;
            if (totalVotes === 0) {
                return 0; // Returnér 0 hvis der ikke er nogen likes eller dislikes
            }
            return Math.round((this.salon.totalLikes / totalVotes) * 100);
        },
    },
    async created() {
        const salonId = this.$route.params.id;
        await this.fetchSalonData(salonId);
        await this.fetchReviews(salonId);
        this.getUserIP();  // Get user IP address
    },
    methods: {
        async fetchSalonData(salonId) {
            const salonDoc = doc(db, "salons", salonId);
            const salonSnap = await getDoc(salonDoc);
            if (salonSnap.exists()) {
                const data = salonSnap.data();  // Hent data fra snap
                this.salon = {
                    id: salonId,
                    totalLikes: data.totalLikes || 0,  // Brug data korrekt
                    totalDislikes: data.totalDislikes || 0,  // Brug data korrekt
                    ...data  // Resten af salon data
                };
            }
        },
        async fetchReviews(salonId) {
            const reviewsCollection = collection(db, "salons", salonId, "reviews");
            const reviewsSnap = await getDocs(reviewsCollection);
            this.reviews = reviewsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        },
        async submitReview() {
            const review = {
                user: "Kunde", // Allow anyone to submit a review
                comment: this.newReview.comment,
                image: this.newReview.image,
                likes: 0,
                dislikes: 0,
                ip: this.userIP  // Add IP address to review
            };

            const reviewCollection = collection(db, "salons", this.salon.id, "reviews");
            await addDoc(reviewCollection, review);
            this.newReview.comment = "";
            this.newReview.image = "";
            this.fetchReviews(this.salon.id);
        },
        uploadImage(event) {
            const file = event.target.files[0];
            const storageRef = ref(storage, `reviews/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                "state_changed",
                null,
                (error) => {
                    console.error("Error uploading image:", error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    this.newReview.image = downloadURL;
                }
            );
        },
        async likeReview(reviewId) {
            if (this.hasVoted) return;  // Prevent multiple votes
            const reviewIndex = this.reviews.findIndex((r) => r.id === reviewId);
            if (reviewIndex !== -1) {
                this.reviews[reviewIndex].likes++;
                this.updateReview(reviewId);
                this.hasVoted = true;  // Mark user as having voted
            }
        },
        async dislikeReview(reviewId) {
            if (this.hasVoted) return;  // Prevent multiple votes
            const reviewIndex = this.reviews.findIndex((r) => r.id === reviewId);
            if (reviewIndex !== -1) {
                this.reviews[reviewIndex].dislikes++;
                this.updateReview(reviewId);
                this.hasVoted = true;  // Mark user as having voted
            }
        },
        async updateReview(reviewId) {
            const reviewDoc = doc(db, "salons", this.salon.id, "reviews", reviewId);
            await updateDoc(reviewDoc, {
                likes: this.reviews.find((review) => review.id === reviewId).likes,
                dislikes: this.reviews.find((review) => review.id === reviewId).dislikes,
            });
        },
        async likeSalon() {
            if (this.hasVotedSalon) return;  // Prevent multiple salon votes
            this.salon.totalLikes++;
            await updateDoc(doc(db, "salons", this.salon.id), {
                totalLikes: this.salon.totalLikes
            });
            this.hasVotedSalon = true;  // Mark user as having voted for salon
        },
        async dislikeSalon() {
            if (this.hasVotedSalon) return;  // Prevent multiple salon votes
            this.salon.totalDislikes++;
            await updateDoc(doc(db, "salons", this.salon.id), {
                totalDislikes: this.salon.totalDislikes
            });
            this.hasVotedSalon = true;  // Mark user as having voted for salon
        },
        async getUserIP() {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                this.userIP = response.data.ip;
            } catch (error) {
                console.error('Could not fetch IP address:', error);
            }
        }
    },
};
</script>

<style scoped>
.salon-profile {
    max-width: 800px;
    margin: auto;
}

/* Header Styles */
.profile-header h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

/* Review Section */
.reviews h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.review-item {
    margin-bottom: 1rem;
}

.review-box {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-user {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.review-comment {
    margin-bottom: 1rem;
    color: #555;
}

.review-image {
    max-width: 100px;
    max-height: 100px;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.like-dislike-section {
    display: flex;
    gap: 8px;
    align-items: center;
}

/* Rating Section */
.like-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.like-meter {
    background-color: #27ae60;
    height: 10px;
    width: 0;
    transition: width 0.5s ease;
}

/* Button Styles */
button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border-radius: 9999px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #333;
}

/* Submit Review Form */
.submit-review-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.submit-review-form button {
    background-color: #000;
    color: white;
    padding: 10px;
    border-radius: 9999px;
}

.submit-review-form button:hover {
    background-color: #333;
}
</style>
