// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import { createMetaManager } from 'vue-meta'; // Vue 3-kompatibel vue-meta

const app = createApp(App);

app.use(router);
app.use(store);
app.use(createMetaManager()); // vue-meta er aktiveret

// Udsender 'render-event', når appen er klar
router.isReady().then(() => {
  app.mount('#app');
  document.dispatchEvent(new Event('render-event'));
});

store.dispatch('fetchUser');
