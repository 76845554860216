// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import BookingPage from '../pages/BookingPage.vue';
import ContactPage from '../pages/ContactPage.vue';
import DashboardPage from '../pages/DashboardPage.vue';
import HairdressersPage from '../pages/HairdressersPage.vue';
import SalonJobs from '../pages/SalonJobs.vue';
import LoginPage from '../pages/LoginPage.vue';
import RegisterPage from '../pages/RegisterPage.vue';
import SalonProfile from '../components/SalonProfile.vue'; // Import the new SalonProfile component
import store from '../store';

const routes = [
  { path: '/', component: HomePage },
  { path: '/booking', component: BookingPage },
  { path: '/contact', component: ContactPage },
  { path: '/dashboard', component: DashboardPage, meta: { requiresAuth: true } },
  { path: '/hairdressers', component: HairdressersPage },
  { path: '/salon-jobs', component: SalonJobs },
  { path: '/login', component: LoginPage },
  { path: '/partner', component: RegisterPage },
  {
    path: '/salon/:id', // Dynamic route to handle each salon profile
    name: 'SalonProfile',
    component: SalonProfile,
    props: true, // Pass the ID parameter as a prop to the component
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  if (store.state.user === null) {
    await store.dispatch('fetchUser');  // Hent brugerens autentificeringsstatus
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
