<template>
  <BaseLayout>
    <PopupComponent />
    <div class="space-y-8">
      <!-- Section 1: Find og Book Frisør Nemt -->
      <section class="text-center space-y-4">
        <h2 class="text-4xl md:text-5xl font-semibold text-black">Find og Book Salon Nemt</h2>
        <h3 class="shine-text text-lg font-semibold text-black">1000+ kunder leder efter frisør på freshcuts.dk</h3>

        <div class="flex flex-wrap justify-center items-center space-x-2 relative">
          <input v-model="address" type="text" placeholder="Indtast by + postnummer" @input="fetchAddressSuggestions"
            class="p-2 border border-gray-300 rounded-lg w-full md:w-1/2 text-gray-900 relative z-20" />
          <button @click="searchSalons"
            class="bg-blue-500 text-white hover:text-white px-3 py-2 rounded-lg transition duration-300 z-20 mt-2 md:mt-0">Søg</button>
          <button @click="getUserLocation"
            class="bg-gray-800 text-white hover:text-white px-3 py-2 rounded-lg transition duration-300 z-20 mt-2 md:mt-0">Nærmeste</button>

          <!-- Autocomplete dropdown -->
          <ul v-if="addressSuggestions.length" class="autocomplete-list">
            <li v-for="(suggestion, index) in addressSuggestions" :key="index" @click="selectSuggestion(suggestion)"
              class="p-2 cursor-pointer hover:bg-gray-200">
              {{ suggestion.tekst }}
            </li>
          </ul>
        </div>

        <h4 class="text-lg font-semibold"> Populære Services</h4>
        <!-- Knapper til valg af service type -->
        <div class="flex flex-wrap justify-center items-center mb-4 space-x-2 md:space-x-4">
          <button v-for="(type, index) in serviceTypes" :key="index"
            :class="['p-2 border rounded-lg text-gray-900 flex-shrink-0', { 'bg-blue-500 text-white': selectedServiceType === type.value }]"
            @click="selectServiceType(type.value)">
            {{ type.icon }} {{ type.label }}
          </button>

        </div>

        <div class="flex flex-col lg:flex-row mt-8 space-y-4 lg:space-y-0 lg:space-x-4">

          <!-- Left Column: Salon List -->
          <div class="lg:w-1/3 w-full space-y-8">
            <h3 class="text-3xl font-bold text-blue-600 mb-4">Frisører nær dig</h3>
            <ul class="space-y-8">
              <li v-for="(salon, index) in filteredSalons" :key="salon.id"
                :class="['salon-card p-8 bg-white bg-opacity-95 rounded-3xl shadow-md text-black', { 'border-l-4 border-blue-500': index === 0 }]">

                <!-- Salon Header -->
                <div class="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 mb-6">

                  <div class="image-wrapper" @click="openImageModal(salon.images)">
                    <img :src="salon.images && salon.images.length ? salon.images[0] : getSalonImage(salon.serviceType)"
                      class="salon-image object-cover rounded-lg shadow-md">
                  </div>

                  <!-- Image Modal (tilføjet modal for billedvisning) -->
                  <div v-if="showImageModal" class="modal">
                    <div class="modal-content">
                      <!-- Luk modal-knap -->
                      <button @click="closeImageModal" class="close-btn">&times;</button>

                      <!-- Viser det valgte billede -->
                      <img :src="selectedImage" alt="Salon Image" class="zoomed-image" />

                      <!-- Billede tæller -->
                      <div class="image-counter">{{ currentImageIndex + 1 }} / {{ salonImages.length }}</div>

                      <!-- Navigation mellem billeder -->
                      <button class="prev-btn" @click="prevImage">&laquo;</button>
                      <button class="next-btn" @click="nextImage(salonImages.length)">&raquo;</button>
                    </div>
                  </div>

                  <div>
                    <h3 class="text-2xl font-semibold text-gray-900">{{ salon.name }}</h3>
                    <p class="text-sm text-gray-500">{{ salon.location }}</p>
                  </div>
                </div>

                <!-- Salon Details -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8 text-gray-700">
                  <div class="space-y-2">
                    <p class="flex justify-between">
                      <span class="font-medium">Afstand:</span>
                      <span>{{ salon.distance ? Number(salon.distance).toFixed(2) : 'Ikke tilgængelig' }} km</span>
                    </p>
                    <p v-if="salon.phone" class="flex justify-between">
                      <span class="font-medium">Telefon:</span>
                      <span>{{ salon.phone }}</span>
                    </p>
                    <div v-if="salon.openingHours">
                      <h4 class="font-medium text-lg">Åbningstider:</h4>
                      <ul class="text-sm space-y-1">
                        <li class="flex justify-between">
                          <span>Hverdage:</span>
                          <span>{{ salon.openingHours.weekdays.open }} - {{ salon.openingHours.weekdays.close }}</span>
                        </li>
                        <li class="flex justify-between">
                          <span>Lørdag:</span>
                          <span>{{ salon.openingHours.saturday.open }} - {{ salon.openingHours.saturday.close }}</span>
                        </li>
                        <li class="flex justify-between">
                          <span>Søndag:</span>
                          <span>{{ salon.openingHours.sunday.open === "" && salon.openingHours.sunday.close === "" ?
                            'Lukket' : salon.openingHours.sunday.open + ' - ' + salon.openingHours.sunday.close
                            }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <h4 class="font-medium text-lg">Services:</h4>
                    <ul class="text-sm space-y-1">
                      <li v-for="service in salon.services" :key="service.name" class="flex justify-between">
                        <span>{{ service.name }}</span>
                        <span>{{ service.price ? service.price + ' DKK' : 'N/A' }}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Beskrivelse af salon -->
                <div v-if="salon.description" class="text-left">
                  <h4 class="font-medium text-lg">Beskrivelse:</h4>
                  <p class="text-sm text-gray-700 cursor-pointer hover:text-blue-500 transition-all duration-300 ease-in-out"
                    @click="isExpanded = !isExpanded">
                    {{ isExpanded ? salon.description : salon.description.slice(0, 150) + '...' }}
                  </p>
                </div>

                <div class="flex justify-between items-center mt-6">
                  <!-- Check if the salon allows online booking -->
                  <button v-if="salon.isOnlineBookingEnabled"
                    class="bg-blue-500 text-white py-2 px-6 rounded-full text-lg font-bold shadow-lg hover:bg-blue-600 transition-all"
                    @click="bookSalon(salon)">Book tid</button>
                  <p class="text-sm text-gray-400">{{ new Date().toLocaleDateString() }}</p>
                </div>



                <!-- Profile Button -->
                <div class="flex justify-between items-center mt-6">
                  <router-link :to="{ name: 'SalonProfile', params: { id: salon.id } }"
                    class="bg-blue-500 text-white py-2 px-6 rounded-full text-lg font-bold shadow-lg hover:bg-blue-600 transition-all">
                    Vis Profil
                  </router-link>
                </div>

                <!-- Reviews Section -->
                <div v-if="salon.reviews && salon.reviews.length" class="mt-8">
                  <h4 class="text-xl font-semibold mb-4 text-gray-900">Seneste kundeanmeldelser:</h4>
                  <div class="relative">
                    <div class="overflow-x-auto whitespace-nowrap space-x-4 py-4" ref="reviewScroll"
                      @mousedown="dragStart" @mousemove="dragMove" @mouseup="dragEnd" @mouseleave="dragEnd">
                      <div v-for="(review, index) in salon.reviews" :key="index"
                        class="inline-block bg-gray-50 p-4 rounded-lg shadow-md min-w-[250px] max-w-[300px]">
                        <p class="font-semibold text-gray-900">{{ review.user }}</p>
                        <p class="text-sm text-gray-600">{{ review.comment }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div v-if="salons.length > displayedSalons.length" class="text-center mt-6">
              <button @click="showMoreSalons"
                class="bg-blue-500 text-white px-4 py-2 rounded-full text-lg font-bold shadow-lg hover:bg-blue-600 transition duration-300">
                Vis flere frisører
              </button>
            </div>
          </div>

          <!-- Right Column: Map -->
          <div class="w-full lg:w-2/3">
            <div class="flex justify-start mb-4">
              <button @click="toggleMap" class="bg-gray-800 text-white px-4 py-2 rounded-lg transition duration-300">
                {{ mapExpanded ? 'Skjul kort' : 'Vis kort' }}
              </button>
            </div>
            <div :class="mapExpanded ? 'block' : 'hidden'" id="map" class="h-80 lg:h-96 rounded-lg shadow-lg"></div>
          </div>

        </div>


      </section>

      <!-- Section 2: About Freshcuts.dk -->
      <section class="text-center space-y-4 bg-gray-100 p-8 rounded-3xl">
        <h2 class="text-3xl font-semibold text-black">Om Freshcuts.dk</h2>
        <p class="text-lg text-gray-700">
          Freshcuts.dk er din foretrukne platform til at finde de bedste frisører i dit område. Vi har samlet de bedste
          frisørsaloner, så du nemt og hurtigt kan finde en frisør, der passer til dine behov. Uanset om du leder efter
          en traditionel klipning, en moderne frisure eller en farvning, kan du finde det hele her.
        </p>
        <p class="text-lg text-gray-700">
          Med over 1200 frisørsaloner på tværs af Danmark og 1000+ tilfredse kunder, er Freshcuts.dk den mest pålidelige
          måde at booke din næste frisøraftale på. Vi værdsætter kvalitet og kundetilfredshed, og vi stræber efter at
          give dig den bedste oplevelse hver gang.
        </p>
      </section>

      <!-- Section 3: Top 3 Salons -->
      <section class="text-center">
        <h2 class="text-2xl font-semibold mb-4 text-black">Top 3 Frisører</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div v-for="salon in top3Salons" :key="salon.id"
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img :src="getSalonImage(salon.serviceType)" alt="" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">{{ salon.name }}</h3>
              <p class="text-sm text-gray-700">{{ salon.location }}</p>
              <p class="text-lg font-bold mt-1 text-blue-500">{{ salon.price }}</p>
              <a :href="salon.website"
                class="bg-blue-500 text-white py-1 px-3 rounded-full transition duration-300 hover:bg-blue-600 text-sm">Besøg
                hjemmeside</a>
            </div>
          </div>
        </div>
      </section>

      <!-- Section 4: Popular Services -->
      <section class="text-center">
        <h2 class="text-2xl font-semibold mb-4 text-black">Populære Services</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1514336937476-a5b961020a5c?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Fade Haircut" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">Fade Klip</h3>
              <p class="text-sm text-gray-700">En populær herrefrisure, hvor håret gradvist bliver kortere mod nakken og
                siderne, hvilket giver et rent og moderne look.</p>
              <p class="text-lg font-bold mt-1 text-blue-500">DKK 199</p>
            </div>
          </div>
          <div
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1456327102063-fb5054efe647?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Men's Haircut" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">Herreklip</h3>
              <p class="text-sm text-gray-700">En klassisk herreklipning, der tilpasser sig dine ønsker for et stilfuldt
                og friskt udseende.</p>
              <p class="text-lg font-bold mt-1 text-blue-500">DKK 199</p>
            </div>
          </div>
          <div
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Women's Haircut" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">Dameklip</h3>
              <p class="text-sm text-gray-700">En professionel klipning, der fremhæver dit ansigt og skaber et elegant
                udtryk.</p>
              <p class="text-lg font-bold mt-1 text-blue-500">DKK 299</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Section 5: New Salons -->
      <section class="text-center">
        <h2 class="text-2xl font-semibold mb-4 text-black">Nye Saloner</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div v-for="salon in newSalons" :key="salon.id"
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img :src="getSalonImage(salon.serviceType)" alt="" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">{{ salon.name }}</h3>
              <p class="text-sm text-gray-700">{{ salon.location }}</p>
              <p class="text-lg font-bold mt-1 text-blue-500">{{ salon.price }}</p>
              <a :href="salon.website"
                class="bg-blue-500 text-white py-1 px-3 rounded-full transition duration-300 hover:bg-blue-600 text-sm">Besøg
                hjemmeside</a>
            </div>
          </div>
        </div>
      </section>

      <!-- Section 6: Conclusion -->
      <section class="text-center space-y-4 bg-gray-100 p-8 rounded-3xl">
        <h2 class="text-3xl font-semibold text-black">Afsluttende Bemærkninger</h2>
        <p class="text-lg text-gray-700">
          Hos Freshcuts.dk er vores mål at gøre det nemt og bekvemt for dig at finde og booke en frisør, der passer til
          dine behov. Vi værdsætter kvalitet og stræber efter at give dig den bedste service hver gang. Tak fordi du
          vælger Freshcuts.dk – vi ser frem til at hjælpe dig med din næste frisøraftale!
        </p>
      </section>
    </div>

    <!-- Modal for Rutevejledning -->
    <div v-if="showModal" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen">
        <div class="bg-white p-6 rounded-lg shadow-lg w-96">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-xl font-semibold">Rutevejledning</h3>
            <button @click="closeModal" class="text-gray-500 hover:text-gray-700">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div id="routeMap" class="h-64"></div>
          <p class="text-gray-600 mt-4">Afstand: {{ modalDistance }} km</p>
          <p class="text-gray-600">Bil tid: {{ modalTime }} min</p>
        </div>
      </div>
    </div>

    <!-- Booking Modal -->
    <BookingModal v-if="selectedSalon" :salon="selectedSalon" @close="closeModal"
      @booking-success="handleBookingSuccess" />
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine'; // Import the routing library
import BaseLayout from '../components/BaseLayout.vue';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import menSalonImage from '../assets/men-salon.jpg';
import womenSalonImage from '../assets/women-salon.jpg';
import defaultSalonImage from '../assets/default-salon.jpg';
import barberIcon from '../assets/barber-pole.png';
import mapPinIcon from '../assets/map-pin.png';
import BookingModal from '../components/BookingModal.vue';
import PopupComponent from '../components/PopupComponent.vue';


const customIcon = L.icon({
  iconUrl: barberIcon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38]
});

export default {
  name: 'HomePage',
  metaInfo() {
    return {
      title: 'Book de Bedste Frisører og Neglesaloner i København | Fade, Herreklip, Gele- og Akrylnegle',
      meta: [
        {
          name: 'description',
          content: 'Find og book tid hos de frisører og neglesaloner i København. Specialister i Fade, Herreklip, Dameklip, Gele- og Akrylnegle. Enkel booking, eksklusiv service'
        }
      ],
      currentImageIndex: 0,
      showModal: false,
      selectedImage: '',
    };
  },
  components: {
    BaseLayout,
    BookingModal,
    PopupComponent,
  },
  data() {
    return {
      selectedServiceType: '', // Ny data property
      serviceTypes: [
        { value: 'Herreklip', label: 'Herreklip', icon: '👨' },
        { value: 'Fade Klipning', label: 'Fade Klipning', icon: '💈' },
        { value: 'Dameklip', label: 'Dameklip', icon: '👩' },
        { value: 'Negle', label: 'Negle', icon: '💅' }, // General category for nail services
        { value: 'Makeup', label: 'Makeup', icon: '💄' }, // General category for makeup services
        { value: 'all', label: 'Alle', icon: '🖤' }  // Option for showing all salons
      ],
      selectedSalon: null,
      address: '',
      addressSuggestions: [],
      salons: [],
      top3Salons: [],
      popularServices: [],
      newSalons: [],
      userLocation: null,
      loading: true,
      mapExpanded: true,
      itemsToShow: 5,
      routingControl: null,
      showModal: false,
      modalDistance: 0,
      modalTime: 0,
      map: null,
      routeMap: null, // New route map for the modal
      showImageModal: false, // Modalens synlighed
      selectedImage: '', // Det valgte billede
      currentImageIndex: 0, // Index for aktuelt billede
      salonImages: [], // Billeder tilhørende den valgte salon
      isExpanded: false,  // Holder styr på, om beskrivelsen er udvidet eller ej
    };
  },
  computed: {
    sortedSalons() {
      return this.salons.slice().sort((a, b) => a.distance - b.distance);
    },
    displayedSalons() {
      return this.sortedSalons.slice(0, this.itemsToShow);
    },
    filteredSalons() {
      // Definer de negle-relaterede tjenester
      const nailServices = [
        "Klassisk Manicure",
        "Spa Manicure",
        "Gel Manicure",
        "Fransk Manicure",
        "Babyboomer Gel Manicure",
        "CND Shellac hænder",
        "CND Shellac fødder",
        "Acryl Negle (fuld sæt)",
        "Gel Negle (fuld sæt)"
      ];

      // Definer de herreklip-relaterede tjenester
      const hairServices = [
        "Herreklip",
        "Fade Klipning",
        "Hår og Skæg Kombi",
        "Skægtrimning og Formning"
      ];

      // Definer de makeup-relaterede tjenester
      const makeupServices = [
        "Brude Makeup inkl. prøve",
        "Fest Makeup",
        "Makeup Vejledning"
      ];

      if (this.selectedServiceType && this.selectedServiceType !== 'all') {
        // Hvis neglebehandling er valgt, filtrer på de relaterede negletjenester
        if (this.selectedServiceType.toLowerCase() === 'negle') {
          const matchingSalons = this.salons.filter(salon =>
            salon.services && salon.services.some(service => nailServices.includes(service.name))
          );
          console.log('Matching salons for nail services:', matchingSalons);
          return matchingSalons;
        }

        // Hvis herreklip eller relateret er valgt, filtrer på de relaterede herretjenester
        if (hairServices.includes(this.selectedServiceType)) {
          const matchingSalons = this.salons.filter(salon =>
            salon.services && salon.services.some(service => hairServices.includes(service.name))
          );
          console.log('Matching salons for hair services:', matchingSalons);
          return matchingSalons;
        }

        // Hvis makeup er valgt, filtrer på de relaterede makeup-tjenester
        if (this.selectedServiceType.toLowerCase() === 'makeup') {
          const matchingSalons = this.salons.filter(salon =>
            salon.services && salon.services.some(service => makeupServices.includes(service.name))
          );
          console.log('Matching salons for makeup services:', matchingSalons);
          return matchingSalons;
        }

        // Almindelig filtrering for andre tjenester
        const matchingSalons = this.salons.filter(salon =>
          salon.services && salon.services.some(service => service.name.toLowerCase() === this.selectedServiceType.toLowerCase())
        );
        console.log('Matching salons for service type:', this.selectedServiceType, matchingSalons);
        return matchingSalons;
      }

      console.log('No specific service type selected or "all" selected. Showing all salons.');
      return this.salons; // Vis alle saloner, hvis "Alle" er valgt
    },
  },
  async created() {
    await this.fetchSalons();  // Henter saloner fra Firestore

    await this.fetchTop3Salons();  // Henter top 3 salons
    await this.fetchNewSalons();  // Henter nye salons

    this.getUserLocation();  // Henter brugerens position

    // Her tester du, om placering er blevet sat korrekt, og afstanden kan beregnes
    this.calculateDistances();  // Beregn afstand for hver salon
  }
  ,
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
    if (this.routingControl) {
      this.routingControl.getPlan().setWaypoints([]);
      this.routingControl = null;
    }
    if (this.routeMap) {
      this.routeMap.remove();
      this.routeMap = null;
    }
  },
  methods: {
    // Åbn modalen og vis det første billede
    openImageModal(images) {
      this.currentImageIndex = 0; // Resetter billedindeks ved åbning af modal
      this.salonImages = images;
      this.selectedImage = images[this.currentImageIndex];
      this.showImageModal = true;
    },
    // Luk modalen
    closeImageModal() {
      this.showImageModal = false;
    },

    // Gå til forrige billede
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      } else {
        this.currentImageIndex = this.salonImages.length - 1;
      }
      this.selectedImage = this.salonImages[this.currentImageIndex];
    },

    // Gå til næste billede
    nextImage(imageLength) {
      if (this.currentImageIndex < imageLength - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0;
      }
      this.selectedImage = this.salonImages[this.currentImageIndex];
    },
    selectServiceType(serviceType) {
      this.selectedServiceType = serviceType;
      this.fetchSalons(); // Opdater salonlisten baseret på valgt service
    },
    bookSalon(salon) {
      this.selectedSalon = salon;
      this.mapExpanded = false; // Skjul kortet når brugeren klikker på "Book tid"
    },
    handleBookingSuccess() {
      alert('Booking successful!');
      this.closeModal();
    },
    closeModal() {
      this.selectedSalon = null; // This will hide the BookingModal
    },
    async fetchSalons() {
      const salonCollection = collection(db, 'salons');
      const salonSnapshot = await getDocs(salonCollection);
      const salonList = [];

      // Loop gennem hver salon og hent reviews for hver af dem
      const fetchPromises = salonSnapshot.docs.map(async salonDoc => {
        const salon = { id: salonDoc.id, ...salonDoc.data() };

        // Hent reviews parallelt for alle saloner
        const reviewsCollection = collection(db, 'salons', salon.id, 'reviews');
        const reviewsSnapshot = await getDocs(reviewsCollection);
        const reviews = reviewsSnapshot.docs.map(doc => doc.data());

        // Tilføj reviews til salon objektet
        salon.reviews = reviews;

        // Tilføj salon til listen
        salonList.push(salon);
      });

      // Afvent alle reviews er hentet før vi fortsætter
      await Promise.all(fetchPromises);

      this.salons = salonList;
      this.calculateDistances();  // Opdater afstande som nødvendigt
      this.loading = false;  // Opdater loading tilstand
    },
    async fetchTop3Salons() {
      this.top3Salons = this.salons.slice(0, 3);
    },
    async fetchNewSalons() {
      this.newSalons = this.salons.slice(-3);
    },
    getSalonImage(serviceType) {
      if (serviceType === 'Men') {
        return menSalonImage;
      } else if (serviceType === 'Women') {
        return womenSalonImage;
      } else {
        return defaultSalonImage;
      }
    },
    async getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handlePositionSuccess,
          this.handlePositionError,
          { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
        );

      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }, handlePositionSuccess(position) {
      const { latitude, longitude } = position.coords;
      this.userLocation = { lat: latitude, lon: longitude };

      console.log('User location fetched automatically: ', this.userLocation);

      // Nu hvor brugerens position er sat, kald calculateDistances for at opdatere afstanden
      this.calculateDistances();
      this.initializeMap();
      this.addUserLocationPin();
    },

    handlePositionError(error) {
      console.error('Error fetching user location:', error.message);  // Log fejlen, hvis geolocation mislykkes
      alert('Vi kunne ikke hente din placering. Vær sikker på, at du har givet tilladelse til at dele din placering.');
      this.loading = false;
    },
    async searchSalons() {
      if (!this.address) {
        console.warn('No address provided for salon search.');
        return;
      }

      try {
        const response = await axios.get('https://nominatim.openstreetmap.org/search', {
          params: {
            q: this.address,
            format: 'json',
            addressdetails: 1,
            limit: 1
          }
        });

        const coordinates = response.data[0];
        const lat = parseFloat(coordinates.lat);
        const lon = parseFloat(coordinates.lon);
        this.userLocation = { lat, lon };

        console.log('User location from address search:', this.userLocation);

        this.initializeMap();
        this.addUserLocationPin();
        this.calculateDistances();  // Beregn afstand efter adressen er søgt
      } catch (error) {
        console.error('Error fetching geocoding data:', error);
      }
    },
    async fetchAddressSuggestions() {
      if (!this.address) {
        this.addressSuggestions = [];
        return;
      }

      try {
        const response = await axios.get('https://api.dataforsyningen.dk/autocomplete', {
          params: {
            q: this.address,
            per_side: 5
          }
        });
        this.addressSuggestions = response.data;
      } catch (error) {
        console.error("Error fetching address suggestions: ", error);
      }
    },
    selectSuggestion(suggestion) {
      this.address = suggestion.tekst;
      this.addressSuggestions = [];
      this.searchSalons();
    },
    calculateDistances() {
      if (!this.userLocation) {
        console.error('No user location available for distance calculation.');
        return; // Stop hvis der ikke er nogen lokation
      }

      this.salons = this.salons.map(salon => {
        if (salon.lat && salon.lon) {
          const distance = this.calculateDistance(
            this.userLocation.lat,
            this.userLocation.lon,
            parseFloat(salon.lat),
            parseFloat(salon.lon)
          );
          console.log(`Distance for ${salon.name}: ${distance.toFixed(2)} km`);  // Tjek afstand
          return { ...salon, distance };
        } else {
          console.warn(`No coordinates available for ${salon.name}`);
          return { ...salon, distance: null };
        }
      });

      this.salons.sort((a, b) => a.distance - b.distance);
      console.log('Salons after distance calculation:', this.salons);  // Test den sorterede liste
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Earth radius in km
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    initializeMap() {
      if (this.map) {
        this.map.setView([this.userLocation.lat, this.userLocation.lon], 13);
        return;
      }

      this.map = L.map('map', {
        zoomAnimation: false, // Deaktiver zoom-animation
      }).setView([this.userLocation.lat, this.userLocation.lon], 13);

      L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://carto.com/attributions">CARTO</a>',
      }).addTo(this.map);

      this.salons.forEach(salon => {
        if (salon.lat && salon.lon) {
          const marker = L.marker([salon.lat, salon.lon], { icon: customIcon }).addTo(this.map);

          marker.on('click', () => {
            const servicesList = salon.services?.slice(0, 3).map(service => `<li>${service.name} - ${service.price || 'N/A'} DKK</li>`).join('') || '';
            const popupContent = `
                  <b>${salon.name}</b><br>
                  ${salon.location}<br>
                  Afstand: ${salon.distance ? salon.distance.toFixed(2) : 'N/A'} km<br>
                  Services:<ul>${servicesList}</ul>
                  <button @click.prevent="showRouteModal(${salon.lat}, ${salon.lon})" class="bg-blue-500 text-white mt-2 px-2 py-1 rounded">Rutevejledning</button>
                `;
            marker.bindPopup(popupContent).openPopup();
          });
        }
      });
    },

    addUserLocationPin() {
      if (this.userLocation) {
        const userIcon = L.icon({
          iconUrl: mapPinIcon,
          iconSize: [50, 50],
          iconAnchor: [25, 50],
          popupAnchor: [0, -50]
        });

        L.marker([this.userLocation.lat, this.userLocation.lon], { icon: userIcon })
          .addTo(this.map)
          .bindPopup(`<b>Din placering</b>`);
      }
    },
    drawRouteToSalon(salonLat, salonLon) {
      if (!this.map) {
        console.error("Map is not initialized.");
        return;
      }

      if (this.routingControl) {
        this.map.removeControl(this.routingControl);
      }

      this.routingControl = L.Routing.control({
        waypoints: [
          L.latLng(this.userLocation.lat, this.userLocation.lon),
          L.latLng(salonLat, salonLon)
        ],
        routeWhileDragging: true,
        showAlternatives: false,
        addWaypoints: false,
        draggableWaypoints: false,
        createMarker: () => null,
        lineOptions: {
          styles: [{ color: '#1c54b2', weight: 5 }]
        }
      }).addTo(this.map);

      this.routingControl.on('routesfound', (e) => {
        const distance = e.routes[0].summary.totalDistance / 1000; // in km
        const time = Math.round(e.routes[0].summary.totalTime / 60); // in minutes

        this.modalDistance = distance.toFixed(2);
        this.modalTime = time;
        this.showModal = true;

        this.$nextTick(() => {
          if (this.routeMap) {
            this.routeMap.remove();
          }

          this.routeMap = L.map('routeMap').setView([this.userLocation.lat, this.userLocation.lon], 13);

          L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://carto.com/attributions">CARTO</a>',
          }).addTo(this.routeMap);

          L.Routing.control({
            waypoints: [
              L.latLng(this.userLocation.lat, this.userLocation.lon),
              L.latLng(salonLat, salonLon)
            ],
            routeWhileDragging: true,
            showAlternatives: false,
            addWaypoints: false,
            draggableWaypoints: false,
            createMarker: () => null,
            lineOptions: {
              styles: [{ color: '#1c54b2', weight: 5 }]
            }
          }).addTo(this.routeMap);
        });
      });
    },
    showRouteModal(salonLat, salonLon) {
      this.drawRouteToSalon(salonLat, salonLon);
    },
    toggleMap() {
      this.mapExpanded = !this.mapExpanded;
      this.$nextTick(() => {
        if (this.mapExpanded && this.map) {
          this.map.invalidateSize();
        }
      });
    },
    showMoreSalons() {
      this.itemsToShow += 5;
    },
  }
}
</script>

<style>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.bg-opacity-80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.backdrop-filter {
  backdrop-filter: blur(20px);
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.transform {
  transform: scale(1);
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}

.transition {
  transition: all 0.5s ease;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.object-cover {
  object-fit: cover;
}

.z-10 {
  z-index: 10;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.shine-text {
  display: inline-block;
  font-weight: bold;
  position: relative;
  color: #000;
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }

  100% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 255, 255, 1);
  }
}

#map,
#routeMap {
  width: 100%;
  height: 575px;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-white {
  background-color: #fff;
}

.autocomplete-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
}

.autocomplete-list li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.autocomplete-list li:hover {
  background-color: #f0f0f0;
}

/* Tilføj stil til modal baggrunden */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.shimmer-effect {
  border-radius: 1.5rem;
  box-shadow: 0 0 0.5px rgba(255, 0, 0, 0.05),
    /* Meget subtil neon-rød */
    0 0 0.5px rgba(0, 0, 255, 0.05),
    /* Meget subtil neon-blå */
    0 0 0.5px rgba(148, 0, 211, 0.05);
  /* Meget subtil neon-lilla */
  animation: shimmer 4s infinite linear;
}

@keyframes shimmer {
  0% {
    box-shadow: 0 0 0.5px rgba(255, 0, 0, 0.05),
      0 0 0.5px rgba(0, 0, 255, 0.05),
      0 0 0.5px rgba(148, 0, 211, 0.05);
  }

  50% {
    box-shadow: 0 0 0.5px rgba(148, 0, 211, 0.05),
      0 0 0.5px rgba(255, 0, 0, 0.05),
      0 0 0.5px rgba(0, 0, 255, 0.05);
  }

  100% {
    box-shadow: 0 0 0.5px rgba(255, 0, 0, 0.05),
      0 0 0.5px rgba(0, 0, 255, 0.05),
      0 0 0.5px rgba(148, 0, 211, 0.05);
  }
}

/* Tilføj en klar outline og en subtil skygge omkring salon kortene */
.salon-card {
  border: 2px solid #4A90E2;
  /* Blå outline for at fremhæve kortene */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtil skygge for dybdeeffekt */
  border-radius: 1.5rem;
  /* Beholder den nuværende afrunding */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.salon-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* Øget skygge ved hover */
}

.salon-card {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-slider {
  position: relative;
  text-align: center;
}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
}

/* Buttons for navigation - Modern and Professional UI */
.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(255, 255, 255);
  /* Light background with transparency for sleek appearance */
  color: #000000;
  /* Darker gray for button text */
  border: 1px solid rgba(0, 0, 0, 0.15);
  /* Light border for subtle definition */
  padding: 10px 16px;
  /* Balanced padding for cleaner look */
  font-size: 22px;
  cursor: pointer;
  border-radius: 12px;
  /* Slight rounding for a modern look */
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth hover effect */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  /* Slight transparency */
}

.prev-btn:hover,
.next-btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.prev-btn {
  left: 16px;
}

.next-btn {
  right: 16px;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  /* Let mørklægning af baggrunden */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


/* Modal Content Style */
.modal-content {
  position: relative;
  background: #fff;
  padding: 0;
  /* Fjern padding for at fjerne den hvide ramme rundt om billedet */
  border-radius: 12px;
  width: 90%;
  /* Responsiv bredde */
  max-width: 500px;
  /* Fast max bredde for større skærme */
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}


.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  font-size: 24px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: rgba(255, 255, 255, 1);
}


/* Zoomed Image */
.zoomed-image {
  max-width: 100%;
  /* Fylder hele modalens bredde */
  max-height: 80vh;
  /* Maksimal højde sat til 80% af viewportens højde */
  object-fit: contain;
  /* Bevarer proportionerne uden at strække billedet */
  border-radius: 12px;
  margin: 0;
  /* Fjern margin for at få billedet til at passe tæt på modalens kanter */
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.image-counter {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 16px;
}

.image-wrapper {
  position: relative;
  width: 100px;
  /* Oprindelig bredde af billedet */
  height: 100px;
  /* Oprindelig højde af billedet */
  overflow: hidden;
  /* Tilføjer en subtil skygge */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.image-wrapper:hover {
  /* Øger skyggen ved hover */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.salon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

/* Når der hover over billedet */
.image-wrapper:hover .salon-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Fyld hele salonkortet */
  height: 100%;
  /* Fyld hele salonkortet */
  object-fit: cover;
  z-index: 10;
  border-radius: 12px;
  /* Ændre evt. til 0 hvis du vil have skarpe hjørner */
}

.salon-card {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.salon-card:hover .salon-image {
  transform: none;
  /* Ingen ændring når hele kortet hover */
}

.salon-card:hover .image-wrapper:hover .salon-image {
  transform: scale(1);
  /* Ingen yderligere forstørrelse, bare fyld kortet */
}

/* Media Queries for Smaller Devices */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    /* Reducer modalens bredde på mindre skærme */
    max-width: 90vw;
    /* Begræns bredde til 90% af skærmens bredde */
    padding: 0;
    /* Ingen padding på mindre skærme */
  }

  .zoomed-image {
    max-width: 100%;
    max-height: 70vh;
    /* Begræns højde yderligere på mindre skærme */
  }
}
</style>
