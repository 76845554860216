<template>
  <BaseLayout>
    <div class="text-center space-y-8">
      <h2 class="text-4xl font-bold my-8 text-gray-900">Vores Frisører</h2>
      <p class="text-lg text-gray-700">
        Mød vores dygtige og erfarne frisører, der er klar til at give dig en fantastisk oplevelse. Herunder finder du en række frisører, hver med deres egen unikke stil og specialer. Klik på "Se Profil" for at lære mere om hver enkelt.
      </p>

      <div class="flex flex-col space-y-4 mt-8">
        <div 
          v-for="hairdresser in hairdressers" 
          :key="hairdresser.id" 
          class="bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 flex items-center space-x-4">
          <img :src="getSalonImage(hairdresser.serviceType)" alt="" class="w-20 h-20 object-cover rounded-full shadow-md">
          <div class="flex flex-col">
            <h3 class="text-xl font-semibold text-blue-500">{{ hairdresser.name }}</h3>
            <p class="text-gray-700">{{ hairdresser.description }}</p>
            <div class="button-container">
              <a :href="hairdresser.website" target="_blank" class="bg-blue-500 text-white py-1 px-4 rounded-full transition duration-300 hover:bg-blue-600 mt-2 inline-block">Se Profil</a>
            </div>
          </div>
        </div>
      </div>

      <section class="text-left space-y-4 mt-12">
        <h3 class="text-3xl font-semibold text-gray-900">Hvorfor vælge vores frisører?</h3>
        <p class="text-lg text-gray-700">
          Vores frisørsalonnetværk består af nogle af de mest talentfulde og erfarne frisører i landet. Hver frisør er dedikeret til at levere en skræddersyet oplevelse, der matcher dine unikke behov og præferencer. Vi tilbyder en bred vifte af tjenester, lige fra klassiske herreklip og moderne dameklipninger til avancerede hårbehandlinger og farvning.
        </p>
        <p class="text-lg text-gray-700">
          Med et stærkt fokus på kundetilfredshed og høj kvalitet arbejder vores frisører tæt sammen med dig for at sikre, at du forlader salonen med en frisure, du er stolt af. Vores frisører holder sig opdateret med de nyeste trends og teknikker inden for hårpleje og styling, hvilket gør dem i stand til at levere moderne og innovative løsninger.
        </p>
        <p class="text-lg text-gray-700">
          Besøg en af vores mange frisørsaloner rundt om i landet, og oplev selv, hvorfor vores kunder vælger os gang på gang. Vi ser frem til at byde dig velkommen og give dig en uforglemmelig frisøroplevelse.
        </p>
      </section>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '../components/BaseLayout.vue';
import menSalonImage from '../assets/men-salon.jpg';
import womenSalonImage from '../assets/women-salon.jpg';
import defaultSalonImage from '../assets/default-salon.jpg';

export default {
  name: 'HairdressersPage',
  metaInfo() {
    return {
      title: 'Salon Jobs - Freshcuts',
      meta: [
        { name: 'description', content: 'Find de nyeste jobopslag for salonarbejde her.' }
      ]
    };
  },
  components: {
    BaseLayout,
  },
  data() {
    return {
      hairdressers: [
        { 
          id: 1, 
          name: "Le Barberie", 
          serviceType: "Men", 
          description: "Le Barberie er kendt for sin autentiske barberoplevelse med fokus på klassisk herreklipning. Beliggende i hjertet af København tilbyder de en kombination af tradition og moderne teknikker. Besøg Le Barberie for en skarp klipning, der kombinerer stil og præcision.",
          website: "https://www.lebarberie.dk"
        },
        { 
          id: 2, 
          name: "Salon Cairo", 
          serviceType: "Men", 
          description: "Salon Cairo er din professionelle fade herrefrisør på Nørrebrogade. Med specialisering i fades og skarpe herreklipninger tilbyder Salon Cairo en unik oplevelse med fokus på håndværk og stil. Gør dig klar til at føle dig selvsikker og frisk efter en behandling hos Salon Cairo.",
          website: "https://www.facebook.com/saloncairojamal/?locale=da_DK"
        },
        { 
          id: 3, 
          name: "KANT", 
          serviceType: "Women", 
          description: "KANT er en moderne frisørsalon beliggende i det pulserende København. De tilbyder skræddersyede frisurer og stylingtjenester til kvinder, der ønsker et unikt og trendy look. Besøg KANT for at opleve frisørkunst på højeste niveau.",
          website: "https://www.hairbykant.com"
        },
        { 
          id: 4, 
          name: "MIPEcph - Mens Corner", 
          serviceType: "Men", 
          description: "MIPEcph - Mens Corner er specialiseret i herreklipninger og grooming. Med en afslappet atmosfære og professionelle tjenester er Mens Corner det perfekte valg for mænd, der ønsker en sofistikeret og stilfuld klipning.",
          website: "https://mipecph.dk"
        },
        { 
          id: 5, 
          name: "Van Den Engh", 
          serviceType: "Women", 
          description: "Van Den Engh er en luksuriøs frisørsalon, der tilbyder eksklusive behandlinger og klipninger for kvinder. Med en passion for detaljer og en stærk dedikation til kvalitet er Van Den Engh stedet for den kræsne kunde.",
          website: "https://www.vandenengh.dk"
        },
        { 
          id: 6, 
          name: "Frisør Pii", 
          serviceType: "Women", 
          description: "Frisør Pii tilbyder en bred vifte af hårbehandlinger og stylingtjenester, der er designet til at fremhæve din unikke stil. Med en central placering i København er Frisør Pii det ideelle valg for kvinder, der ønsker det bedste inden for hårpleje.",
          website: "https://www.frisorpii.dk"
        },
        { 
          id: 7, 
          name: "Hair by Kern", 
          serviceType: "Women", 
          description: "Hair by Kern er kendt for sin kreative tilgang til hårstyling og klipning. Med et team af erfarne frisører, der konstant opdaterer sig med de nyeste trends, kan du forvente en personlig og unik oplevelse.",
          website: "https://hairbykern.dk"
        },
        { 
          id: 8, 
          name: "Studio Cim Mahony", 
          serviceType: "Women", 
          description: "Studio Cim Mahony tilbyder en kunstnerisk tilgang til hårstyling med fokus på innovation og kreativitet. Beliggende i det elegante København, er denne salon et must for dem, der ønsker en frisør, der kan tænke uden for boksen.",
          website: "https://studiocimmahony.com"
        },
        { 
          id: 9, 
          name: "HairLab", 
          serviceType: "Women", 
          description: "HairLab er din destination for avancerede hårbehandlinger og klipninger. Med en moderne tilgang og brug af den nyeste teknologi tilbyder HairLab løsninger, der får dit hår til at skinne.",
          website: "https://hairlab.dk"
        },
        { 
          id: 10, 
          name: "Ny Test Frisør - åbningstider! update check", 
          serviceType: "Frisør", 
          description: "Denne test frisør giver dig mulighed for at opleve vores nyeste tjenester og åbningstider. Vi stræber efter at give dig den bedste service og en uforglemmelig oplevelse.",
          website: "https://freshcuts.dk/"
        },
        { 
          id: 11, 
          name: "Salon Platin", 
          serviceType: "Women", 
          description: "Salon Platin er en førsteklasses frisørsalon, der leverer exceptionelle klipninger og farvningstjenester. Med en hyggelig atmosfære og et talentfuldt team, er Salon Platin stedet for kvinder, der ønsker en skræddersyet frisure.",
          website: "https://platinhair.dk"
        },
        { 
          id: 12, 
          name: "Golden Touch GT", 
          serviceType: "Men", 
          description: "Golden Touch GT er kendt for sin ekspertise inden for herreklipninger og grooming. Med en eksklusiv beliggenhed på Nørrebrogade tilbyder de førsteklasses tjenester i en luksuriøs atmosfære.",
          website: "https://www.goldentouchgt.dk/"
        },
        { 
          id: 13, 
          name: "Beauty Factory by Lorand Bali", 
          serviceType: "Women", 
          description: "Beauty Factory by Lorand Bali er din destination for omfattende skønheds- og hårplejetjenester. Med en passion for detaljer og en dedikation til kundeservice tilbyder de en uforglemmelig oplevelse.",
          website: "https://www.beauty-factory.dk"
        },
        { 
          id: 14, 
          name: "Fonnesbek Hair", 
          serviceType: "Women", 
          description: "Fonnesbek Hair tilbyder eksklusive behandlinger og klipninger for den moderne kvinde. Med et stærkt fokus på kvalitet og kundetilfredshed er denne salon det ideelle valg for en luksuriøs oplevelse.",
          website: "https://fonnesbekhair.dk"
        },
        { 
          id: 15, 
          name: "Salon Eiffel", 
          serviceType: "Men", 
          description: "Salon Eiffel er din professionelle herrefrisør beliggende på Amagerbrogade. De specialiserer sig i klassiske herreklipninger og moderne frisurer, der passer til enhver stil.",
          website: "https://www.fresha.com/a/salon-eiffel-kobenhavn-amagerbrogade-61-s-pf0x3z8p"
        },
        { 
          id: 16, 
          name: "Frisør Golden Cut", 
          serviceType: "Frisør", 
          description: "Frisør Golden Cut er kendt for sin evne til at skabe fantastiske frisurer for både mænd, kvinder og børn. Med fokus på kundetilfredshed og høj kvalitet, er det stedet for hele familien.",
          website: "https://www.facebook.com/goldencutborg/?locale=da_DK"
        },
        { 
          id: 17, 
          name: "Frisør Koch", 
          serviceType: "Women", 
          description: "Frisør Koch er en af Københavns førende frisørsaloner med en stærk tradition for kvalitet og service. Med en central beliggenhed og et talentfuldt team er de klar til at give dig en frisure, du vil elske.",
          website: "https://www.frisorkoch.dk"
        },
        { 
          id: 18, 
          name: "Salon Empire", 
          serviceType: "Men", 
          description: "Salon Empire er en eksklusiv herrefrisør, der tilbyder en bred vifte af tjenester fra klassiske klipninger til avancerede skægtrimninger og ansigtsbehandlinger. Besøg Salon Empire for en luksuriøs oplevelse.",
          website: "https://salonempire.setmore.com/"
        },
        { 
          id: 19, 
          name: "Salon Nasim", 
          serviceType: "Women", 
          description: "Salon Nasim er kendt for sine skræddersyede frisurer og behandlinger, der fremhæver din naturlige skønhed. Med et dedikeret team er de klar til at skabe din drømmefrisure.",
          website: "https://www.salonnasim.dk"
        },
        { 
          id: 20, 
          name: "Salon Maria", 
          serviceType: "Women", 
          description: "Salon Maria tilbyder et bredt udvalg af hårpleje- og stylingtjenester for kvinder. Deres professionelle team er kendt for deres kreative løsninger og høje standarder.",
          website: "https://www.salonmaria.info"
        },
        { 
          id: 21, 
          name: "Barbero", 
          serviceType: "Men", 
          description: "Barbero er din herrefrisør på Amager, der specialiserer sig i klassiske og moderne frisurer. Med en afslappet atmosfære og en professionel tilgang får du altid en klipning, du kan være stolt af.",
          website: "https://el-barbero.planway.com/"
        },
        { 
          id: 22, 
          name: "RODEO", 
          serviceType: "Frisør", 
          description: "RODEO er en dynamisk frisørkæde med flere saloner i København. Deres frisører er eksperter i at skabe moderne looks til både mænd og kvinder, med en fokus på kreativitet og kundeservice.",
          website: "https://rodeo.dk/"
        },
        { 
          id: 23, 
          name: "Masterklip", 
          serviceType: "Men", 
          description: "Masterklip er din lokale herrefrisør, der tilbyder hurtige og præcise klipninger. Med et erfarent team og en hyggelig atmosfære er Masterklip stedet for den moderne mand.",
          website: "https://www.masterklip.dk"
        },
        { 
          id: 24, 
          name: "Klemmes", 
          serviceType: "Default", 
          description: "Klemmes er en all-round frisør, der tilbyder både klassiske og moderne frisurer. Med en central beliggenhed og et dedikeret team kan du være sikker på at få en fantastisk oplevelse.",
          website: "https://klemmes.dk"
        },
        { 
          id: 25, 
          name: "Salon Skak Saks", 
          serviceType: "Default", 
          description: "Salon Skak Saks er kendt for sin unikke tilgang til hårklip og styling. Deres frisører er altid opdaterede på de nyeste trends og teknikker, hvilket gør dem til et foretrukket valg for mange.",
          website: "https://studiocimmahony.com"
        }
      ]
    };
  },
  methods: {
    getSalonImage(serviceType) {
      if (serviceType === 'Men') {
        return menSalonImage;
      } else if (serviceType === 'Women') {
        return womenSalonImage;
      } else {
        return defaultSalonImage;
      }
    }
  }
}
</script>

<style>
.button-container {
  display: flex;
  justify-content: flex-start; /* Justerer knappen til at være venstrestillet */
}

.button-container a {
  display: inline-block; /* Sørger for, at knappen ikke bliver strukket */
  padding: 10px 20px; /* Tilføjer lidt ekstra polstring for at gøre knappen mere kompakt */
  text-align: center;
  background-color: #4299e1;
  color: white;
  border-radius: 9999px; /* Gør knappen rund */
  transition: background-color 0.3s;
  width: auto; /* Sørger for, at bredden er baseret på indholdet */
}

.button-container a:hover {
  background-color: #3182ce; /* Ændrer farven ved hover for en fin effekt */
}

.bg-opacity-80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.backdrop-filter {
  backdrop-filter: blur(20px);
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.transition {
  transition: all 0.5s ease;
}

.transform {
  transform: scale(1);
}

.hover\\:scale-105:hover {
  transform: scale(1.05);
}

.object-cover {
  object-fit: cover;
}

.z-10 {
  z-index: 10;
}

.text-gray-900 {
  color: #1a202c;
}

.text-gray-700 {
  color: #4a5568;
}

.text-blue-500 {
  color: #4299e1;
}
</style>
