<template>
  <BaseLayout>
    <div class="container mx-auto p-6">
      <!-- Sektion til at tjekke bookingstatus -->
      <div class="text-center mb-12">
        <h2 class="text-4xl font-semibold mb-8">Tjek din Bookingstatus</h2>
        <input 
          v-model="contactInfo" 
          placeholder="Indtast dit telefonnummer eller email" 
          class="w-full md:w-1/2 p-3 border border-gray-300 rounded-full text-gray-900 mb-4" 
        />
        <button 
          @click="checkBookingStatus" 
          class="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-full transition duration-300"
        >
          Tjek Status
        </button>

        <!-- Visning af bookings -->
        <div v-if="sortedBookings.length > 0" class="mt-8">
          <h3 class="text-2xl font-semibold mb-4 text-left">Dine Bookinger</h3>
          <div class="flex justify-end items-center mb-4">
            <span class="text-lg text-gray-700 mr-2">Dato</span>
            <button @click="toggleSortOrder" class="focus:outline-none">
              <svg
                v-if="sortOrder === 'newest'"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div 
              v-for="booking in sortedBookings" 
              :key="booking.id" 
              :class="{'bg-dark-card p-6 rounded-3xl shadow-lg transition-all duration-300 hover:shadow-xl': true, 
                       'recent-booking': isMostRecentBooking(booking)}"
            >
              <div class="flex justify-between items-center mb-4">
                <div class="text-left">
                  <h4 class="text-xl font-bold text-white">{{ booking.salonName }}</h4>
                  <p class="text-gray-400">{{ booking.date }} - {{ booking.time }}</p>
                  <p class="text-gray-400">{{ booking.salonLocation }}</p>
                </div>
                <div>
                  <span class="px-3 py-1 rounded-full text-lg font-bold"
                    :class="booking.status === 'approved' ? 'bg-green-500 text-white' : 'bg-white text-black'">
                    {{ booking.status === 'approved' ? 'Godkendt' : 'Afventer' }}
                  </span>
                </div>
              </div>
              <div class="mt-4 text-left">
                <p class="text-xl text-white"><strong>Service:</strong> {{ booking.service }}</p>
                <p class="text-xl text-white"><strong>Noter:</strong> {{ booking.notes || 'Ingen noter' }}</p>
              </div>
              <div class="mt-4 flex justify-end items-center">
                <!-- Her kan du tilføje flere handlinger, hvis nødvendigt -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="error" class="mt-8 text-red-500">{{ error }}</div>
      </div>

      <!-- Sektion til booking -->
      <div v-if="!selectedSalon" class="text-center">
        <h2 class="text-4xl font-semibold mb-8">Vælg en Frisør</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div 
            v-for="salon in salons" 
            :key="salon.id" 
            class="bg-white p-6 rounded-3xl shadow-md hover:shadow-lg transition duration-300 cursor-pointer"
            @click="selectSalon(salon)">
            <h4 class="text-xl font-semibold">{{ salon.name }}</h4>
            <p class="text-gray-600">{{ salon.description }}</p>
          </div>
        </div>
      </div>

      <div v-else-if="!selectedService" class="text-center">
        <h2 class="text-4xl font-semibold mb-8">Vælg en Tjeneste</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div 
            v-for="service in selectedSalon.services" 
            :key="service.name" 
            class="bg-white p-6 rounded-3xl shadow-md hover:shadow-lg transition duration-300 cursor-pointer"
            @click="selectService(service)">
            <h4 class="text-xl font-semibold">{{ service.name }}</h4>
            <p class="text-gray-600">{{ service.price }}</p>
          </div>
        </div>
        <button 
          @click="resetSelection" 
          class="mt-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-full transition duration-300"
        >
          Tilbage
        </button>
      </div>

      <div v-else class="flex justify-center items-center min-h-screen">
        <div class="bg-white bg-opacity-60 backdrop-filter backdrop-blur-3xl p-8 rounded-3xl shadow-2xl text-gray-900 w-full max-w-lg">
          <h2 class="text-4xl font-semibold mb-8 text-center">Booking</h2>
          <form @submit.prevent="book" class="space-y-6">
            <div>
              <label for="name" class="block text-lg">Navn</label>
              <input type="text" id="name" v-model="name" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="phone" class="block text-lg">Telefon</label>
              <input type="tel" id="phone" v-model="phone" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="email" class="block text-lg">Email</label>
              <input type="email" id="email" v-model="email" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="date" class="block text-lg">Dato</label>
              <input type="date" id="date" v-model="date" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="time" class="block text-lg">Tid</label>
              <input type="time" id="time" v-model="time" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <button 
              type="submit" 
              class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition duration-300"
            >
              Book
            </button>
          </form>
        </div>
        <button 
          @click="resetSelection" 
          class="mt-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-full transition duration-300"
        >
          Tilbage
        </button>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { db } from '../firebase';
import { collection, getDocs, query, where, addDoc, doc, getDoc } from 'firebase/firestore';
import BaseLayout from '../components/BaseLayout.vue';
import emailjs from '@emailjs/browser';

export default {
  metaInfo() {
    return {
      title: 'Salon Jobs - Freshcuts',
      meta: [
        { name: 'description', content: 'Find de nyeste jobopslag for salonarbejde her.' }
      ]
    };
  },
  name: 'BookingPage',
  components: {
    BaseLayout,
  },
  data() {
    return {
      salons: [],
      selectedSalon: null,
      selectedService: null,
      contactInfo: '',  // Ny data property til telefonnummer eller email
      bookings: [],     // Opbevar fundne bookinger
      sortOrder: 'newest',  // Sorteringsordre
      name: '',
      phone: '',
      email: '',
      date: '',
      time: '',
      error: null,      // Error message
    };
  },
  computed: {
    sortedBookings() {
      return this.bookings.slice().sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    }
  },
  async created() {
    await this.fetchSalons();  // Fetch the salons when the component is created
  },
  methods: {
    isMostRecentBooking(booking) {
      return booking === this.sortedBookings[0];
    },
    toggleSortOrder() {
      this.sortOrder = this.sortOrder === 'newest' ? 'oldest' : 'newest';
      this.bookings.reverse(); // Reverser ordren af bookings
    },
    async fetchSalons() {
      try {
        const salonCollection = collection(db, 'salons');
        const salonSnapshot = await getDocs(salonCollection);
        this.salons = salonSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log(this.salons);  // Debugging: Check if salons are fetched correctly
      } catch (error) {
        console.error("Error fetching salons: ", error);
        alert("Fejl ved hentning af frisører. Tjek dine Firestore regler.");
      }
    },
    selectSalon(salon) {
      this.selectedSalon = salon;
    },
    selectService(service) {
      this.selectedService = service;
    },
    async checkBookingStatus() {
      try {
        const isEmail = this.contactInfo.includes('@');
        let q;

        if (isEmail) {
          q = query(collection(db, 'bookings'), where('email', '==', this.contactInfo));
        } else {
          q = query(collection(db, 'bookings'), where('phone', '==', this.contactInfo));
        }

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          this.bookings = await Promise.all(
            querySnapshot.docs.map(async (docSnapshot) => {
              const bookingData = docSnapshot.data();
              const salonDocRef = doc(db, 'salons', bookingData.salonId);
              const salonDoc = await getDoc(salonDocRef);
              return {
                ...bookingData,
                salonLocation: salonDoc.exists() ? salonDoc.data().location : 'Ukendt',
                salonName: salonDoc.exists() ? salonDoc.data().name.toUpperCase() : 'Ukendt', // Frisør navn i store bogstaver
              };
            })
          );
          this.error = null;
        } else {
          this.error = 'Ingen booking fundet for dette telefonnummer eller email.';
          this.bookings = [];
        }
      } catch (error) {
        this.error = 'Der opstod en fejl ved hentning af bookingoplysninger.';
        console.error(error);
      }
    },
    async book() {
      try {
        await addDoc(collection(db, 'bookings'), {
          salonId: this.selectedSalon.id,
          salonName: this.selectedSalon.name, // Tilføj salon navn
          service: this.selectedService.name,
          name: this.name,
          phone: this.phone,
          email: this.email,
          date: this.date,
          time: this.time,
          status: 'pending',
        });

        const salonOwnerRef = doc(db, 'users', this.selectedSalon.id);
        const salonOwnerDoc = await getDoc(salonOwnerRef);
        if (salonOwnerDoc.exists()) {
          const salonOwnerEmail = salonOwnerDoc.data().email;

          await this.sendEmail(
            salonOwnerEmail,
            this.selectedSalon.name,
            this.selectedService.name,
            this.date,
            this.time,
            this.name,
            this.phone
          );
        } else {
          console.error("No such document in users collection!");
        }

        alert('Booking successful');
        this.resetSelection();
      } catch (error) {
        console.error("Error creating booking: ", error);
        alert("Fejl ved oprettelse af booking. Tjek dine Firestore regler.");
      }
    },
    async sendEmail(to, salonName, serviceName, date, time, customerName, customerPhone) {
      try {
        const templateParams = {
          to_name: salonName,
          service: serviceName,
          date: date,
          time: time,
          customer_name: customerName,
          customer_phone: customerPhone,
          to_email: to,
        };

        await emailjs.send('service_ehb98xm', 'template_9tnba2y', templateParams, 'f8drQdDrH5AfEidz-');
        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    },
    resetSelection() {
      this.selectedService = null;
      this.selectedSalon = null;
      this.name = '';
      this.phone = '';
      this.email = '';
      this.date = '';
      this.time = '';
      this.contactInfo = ''; // Nulstil kontaktoplysninger
      this.bookings = [];    // Nulstil fundne bookinger
      this.error = null;     // Nulstil eventuelle fejl
    }
  }
};
</script>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.rounded-3xl {
  border-radius: 1.5rem;
}
.shadow-2xl {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
}
.bg-opacity-60 {
  background-color: rgba(255, 255, 255, 0.6);
}
.backdrop-filter {
  backdrop-filter: blur(25px);
}

.bg-dark-card {
  background-color: #2e2e2e; /* Mørk farve til kort */
}

.text-white {
  color: white;
}

.text-xl {
  font-size: 1.25rem;
}

.text-left {
  text-align: left;
}

/* CSS for the shimmering outline effect */
.recent-booking {
  border-radius: 1.5rem; /* Match the card's border-radius */
  box-shadow: 0 0 15px 8px rgba(66, 165, 245, 0.8), 
              0 0 30px 16px rgba(114, 124, 182, 0.8), 
              0 0 45px 24px rgba(118, 75, 162, 0.8);
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% {
    box-shadow: 0 0 15px 8px rgba(66, 165, 245, 0.8), 
                0 0 30px 16px rgba(114, 124, 182, 0.8), 
                0 0 45px 24px rgba(118, 75, 162, 0.8);
  }
  50% {
    box-shadow: 0 0 15px 8px rgba(118, 75, 162, 0.8), 
                0 0 30px 16px rgba(66, 165, 245, 0.8), 
                0 0 45px 24px rgba(114, 124, 182, 0.8);
  }
  100% {
    box-shadow: 0 0 15px 8px rgba(66, 165, 245, 0.8), 
                0 0 30px 16px rgba(114, 124, 182, 0.8), 
                0 0 45px 24px rgba(118, 75, 162, 0.8);
  }
}

</style>
