<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-6 rounded-2xl shadow-2xl text-gray-900 w-full max-w-md">
      <h2 class="text-3xl font-semibold mb-2 text-center">Booking</h2>
      <p class="text-md text-center text-gray-600 mb-4">{{ salon.name }}</p>

      <!-- Step 1: Service Selection -->
      <div v-if="step === 1" class="space-y-4">
        <div>
          <label for="service" class="block text-base">Vælg Service</label>
          <select id="service" v-model="selectedService"
            class="w-full p-2 border border-gray-300 rounded-full text-gray-900" required>
            <option v-for="service in salon.services" :key="service.name" :value="service.name">
              {{ service.name }} - {{ service.price }} DKK
            </option>
          </select>
        </div>
        <div>
          <label for="notes" class="block text-base">Specifikke ønsker eller kommentarer</label>
          <textarea id="notes" v-model="customerNotes"
            class="w-full p-2 border border-gray-300 rounded-full text-gray-900"></textarea>
        </div>
        <button @click="nextStep"
          class="bg-blue-500 hover:bg-blue-600 text-white w-full py-2 rounded-full transition duration-300">Næste</button>
      </div>

      <!-- Step 2: Date and Time Selection -->
<div v-if="step === 2" class="space-y-4">
  <div>
    <label for="date" class="block text-base">Vælg Dato</label>
    <input type="date" id="date" v-model="date" @change="fetchAvailableTimes" :min="minDate"
      class="w-full p-2 border border-gray-300 rounded-lg text-gray-900 bg-gray-50 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400" required>
  </div>
  <div>
    <label for="time" class="block text-base">Vælg Tidspunkt</label>
    <div class="grid grid-cols-3 gap-2">
      <button v-for="timeSlot in availableTimes" :key="timeSlot.time" @click="time = timeSlot.time"
        :disabled="!timeSlot.available"
        :class="['py-2 px-4 rounded-full', timeSlot.available ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-600', time === timeSlot.time ? 'ring-2 ring-blue-500' : '']">
        {{ timeSlot.time }}
      </button>
    </div>
  </div>
  <div class="flex space-x-2">
    <button @click="prevStep"
      class="bg-gray-500 hover:bg-gray-600 text-white w-1/2 py-2 rounded-full transition duration-300">Tilbage</button>
    <button @click="nextStep" :disabled="!time"
      class="bg-blue-500 hover:bg-blue-600 text-white w-1/2 py-2 rounded-full transition duration-300">Næste</button>
  </div>
</div>

<!-- Step 3: Contact Information -->
<div v-if="step === 3" class="space-y-4">
  <div>
    <label for="name" class="block text-base">Navn</label>
    <input type="text" id="name" v-model="name"
      class="w-full p-2 border border-gray-300 rounded-full text-gray-900" required>
  </div>
  <div>
    <label for="phone" class="block text-base">Telefon</label>
    <input type="tel" id="phone" v-model="phone"
      class="w-full p-2 border border-gray-300 rounded-full text-gray-900" required>
  </div>
  <div>
    <label for="email" class="block text-base">Email</label>
    <input type="email" id="email" v-model="email"
      class="w-full p-2 border border-gray-300 rounded-full text-gray-900" required>
  </div>
  <div class="flex space-x-2">
    <button @click="prevStep"
      class="bg-gray-500 hover:bg-gray-600 text-white w-1/2 py-2 rounded-full transition duration-300">Tilbage</button>
    <button @click="nextStep"
      class="bg-blue-500 hover:bg-blue-600 text-white w-1/2 py-2 rounded-full transition duration-300">Næste</button>
  </div>
</div>


      <!-- Step 4: Confirmation -->
      <div v-if="step === 4" class="space-y-4">
        <h3 class="text-lg font-semibold">Bekræft Aftale</h3>
        <p><strong>Service:</strong> {{ selectedService }}</p>
        <p><strong>Dato:</strong> {{ date }}</p>
        <p><strong>Tid:</strong> {{ time }}</p>
        <p><strong>Navn:</strong> {{ name }}</p>
        <p><strong>Telefon:</strong> {{ phone }}</p>
        <p><strong>Email:</strong> {{ email }}</p>
        <p><strong>Ønsker/Kommentarer:</strong> {{ customerNotes }}</p>
        <button @click="book"
          class="bg-blue-500 hover:bg-blue-600 text-white w-full py-2 rounded-full transition duration-300">Bekræft</button>
        <button @click="prevStep"
          class="bg-gray-500 hover:bg-gray-600 text-white w-full py-2 rounded-full transition duration-300">Tilbage</button>
      </div>

      <!-- Close Button -->
      <button @click="$emit('close')"
  class="mt-4 bg-red-500 hover:bg-red-600 text-white px-3 py-2 rounded-full transition duration-300">Luk</button>

    </div>
  </div>
</template>

<script>
import { db } from '../firebase';
import { addDoc, collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import emailjs from '@emailjs/browser';

export default {
  name: 'BookingModal',
  props: {
    salon: Object,
  },
  data() {
    return {
      step: 1,
      selectedService: '',
      name: '',
      phone: '',
      email: '',
      date: '',
      time: '',
      customerNotes: '',
      availableTimes: [], // For the time slots
      bookedTimes: [], // Stores already-booked times
      minDate: new Date().toISOString().split('T')[0], // Disable past dates
    };
  },
  methods: {
    nextStep() {
      if (this.step < 4) {
        this.step++;
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    async fetchAvailableTimes() {
      if (!this.date) return;

      const selectedDate = new Date(this.date);
      const dayOfWeek = selectedDate.getDay(); // 0 = Sunday, 1 = Monday, etc.

      let openTime, closeTime;
      if (dayOfWeek === 0 && this.salon.openingHours.sunday.open) {
        openTime = this.salon.openingHours.sunday.open;
        closeTime = this.salon.openingHours.sunday.close;
      } else if (dayOfWeek === 6 && this.salon.openingHours.saturday.open) {
        openTime = this.salon.openingHours.saturday.open;
        closeTime = this.salon.openingHours.saturday.close;
      } else if (this.salon.openingHours.weekdays.open) {
        openTime = this.salon.openingHours.weekdays.open;
        closeTime = this.salon.openingHours.weekdays.close;
      }

      if (openTime && closeTime) {
        await this.fetchBookedTimes(); // Fetch already-booked times for the selected date
        this.availableTimes = this.generateTimeSlots(openTime, closeTime).map((timeSlot) => ({
          time: timeSlot,
          available: !this.bookedTimes.includes(timeSlot), // Disable booked times
        }));
      } else {
        this.availableTimes = []; // No available times if the salon is closed
      }
    },
    generateTimeSlots(openTime, closeTime) {
      const times = [];
      let currentTime = new Date(`${this.date}T${openTime}:00`);
      const endTime = new Date(`${this.date}T${closeTime}:00`);

      while (currentTime < endTime) {
        times.push(currentTime.toTimeString().slice(0, 5)); // HH:mm format
        currentTime = new Date(currentTime.getTime() + 30 * 60000); // Increment by 30 minutes
      }

      return times;
    },
    async fetchBookedTimes() {
      const bookedTimesQuery = query(
        collection(db, 'bookings'),
        where('salonId', '==', this.salon.id),
        where('date', '==', this.date)
      );

      const bookedTimesSnapshot = await getDocs(bookedTimesQuery);
      this.bookedTimes = bookedTimesSnapshot.docs.map(doc => doc.data().time);
    },
    async book() {
      try {
        await addDoc(collection(db, 'bookings'), {
          salonId: this.salon.id,
          service: this.selectedService,
          name: this.name,
          phone: this.phone,
          email: this.email,
          date: this.date,
          time: this.time,
          notes: this.customerNotes, // Store the customer notes in the booking
          status: 'pending',
        });

        const salonOwnerRef = doc(db, 'users', this.salon.id);
        const salonOwnerDoc = await getDoc(salonOwnerRef);
        if (salonOwnerDoc.exists()) {
          const salonOwnerEmail = salonOwnerDoc.data().email;
          await this.sendEmail(
            salonOwnerEmail,
            this.salon.name,
            this.selectedService,
            this.date,
            this.time,
            this.name,
            this.phone
          );
        }

        this.$emit('bookingSuccess');
      } catch (error) {
        console.error("Error creating booking: ", error);
      }
    },
    async sendEmail(to, salonName, serviceName, date, time, customerName, customerPhone) {
      try {
        const templateParams = {
          to_name: salonName,
          service: serviceName,
          date: date,
          time: time,
          customer_name: customerName,
          customer_phone: customerPhone,
          to_email: to,
        };

        await emailjs.send('service_ehb98xm', 'template_9tnba2y', templateParams, 'f8drQdDrH5AfEidz-');
        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    },
  },
};
</script>

<style scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
