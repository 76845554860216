<template>
  <BaseLayout>
    <div class="container mx-auto p-6">
      <h1 class="text-5xl font-semibold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-900">
        Salon Jobs
      </h1>

      <!-- Toggle posting type (modern switch style) -->
      <div class="toggle-posting-type mb-12 flex justify-center">
        <div class="relative inline-flex">
          <button
            :class="{'bg-white text-gray-700': postingType === 'seeker', 'bg-gray-300 text-gray-500': postingType !== 'seeker'}"
            @click="postingType = 'seeker'"
            class="rounded-l-full px-6 py-2 text-lg font-semibold transition-colors duration-300"
          >
            Jeg søger et job
          </button>
          <button
            :class="{'bg-white text-gray-700': postingType === 'poster', 'bg-gray-300 text-gray-500': postingType !== 'poster'}"
            @click="postingType = 'poster'"
            class="rounded-r-full px-6 py-2 text-lg font-semibold transition-colors duration-300"
          >
            Jeg slår et job op
          </button>
        </div>
      </div>

      <!-- Form for job seekers and job posters -->
      <form @submit.prevent="submitPost" class="bg-white p-8 rounded-3xl shadow-2xl max-w-lg mx-auto text-gray-900 mb-12">
        <div v-if="postingType === 'seeker'">
          <h2 class="text-3xl font-bold mb-6 text-center">Jobsøger Formular</h2>
          <input v-model="jobSeeker.name" placeholder="Dit navn" class="w-full p-3 mb-4 border border-gray-500 rounded-full" required />
          <input v-model="jobSeeker.age" placeholder="Din alder" type="number" class="w-full p-3 mb-4 border border-gray-500 rounded-full" required />
          <input v-model="jobSeeker.location" placeholder="Ønsket arbejdssted" class="w-full p-3 mb-4 border border-gray-500 rounded-full" required />
          <textarea v-model="jobSeeker.description" placeholder="Beskriv dine færdigheder" class="w-full p-3 mb-4 border border-gray-500 rounded-3xl" rows="4" required></textarea>
          <label class="block mb-2 text-sm font-medium text-gray-700">Upload filer</label>
          <input type="file" @change="onFileChange" multiple accept="image/*" class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none bg-gray-50" />
        </div>

        <div v-else>
          <h2 class="text-3xl font-bold mb-6 text-center">Salon Job Formular</h2>
          <input v-model="jobPoster.salonName" placeholder="Salon navn" class="w-full p-3 mb-4 border border-gray-500 rounded-full" required />
          <input v-model="jobPoster.location" placeholder="Salon beliggenhed" class="w-full p-3 mb-4 border border-gray-500 rounded-full" required />
          <textarea v-model="jobPoster.jobDescription" placeholder="Jobbeskrivelse" class="w-full p-3 mb-4 border border-gray-500 rounded-3xl" rows="4" required></textarea>
          <input v-model="jobPoster.contactEmail" placeholder="Kontakt email" type="email" class="w-full p-3 mb-4 border border-gray-500 rounded-full" required />
          <label class="block mb-2 text-sm font-medium text-gray-700">Upload filer</label>
          <input type="file" @change="onFileChange" multiple accept="image/*" class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none bg-gray-50" />
        </div>
        <br>
        <button type="submit" class="bg-gradient-to-r from-gray-700 to-gray-900 text-white w-full py-3 rounded-full transition duration-300 font-semibold hover:opacity-90">
          {{ postingType === 'seeker' ? 'Opslå som jobsøger' : 'Opslå job' }}
        </button>
      </form>

      <!-- Display job listings: jobs seekers and salon jobs in two columns -->
      <div class="job-listings mt-12 grid grid-cols-1 md:grid-cols-2 gap-12">
        <!-- Column for job seekers -->
        <div>
          <h2 class="text-4xl font-semibold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500">
            Jobsøgere
          </h2>
          <div v-if="jobSeekers.length > 0" class="grid grid-cols-1 gap-6">
            <div v-for="jobSeeker in jobSeekers" :key="jobSeeker.id" class="bg-white shadow-lg rounded-3xl p-6 transform transition duration-300 hover:scale-105">
              <div v-if="jobSeeker.imageUrls" class="scrollable-images mb-4">
                <div v-for="imageUrl in jobSeeker.imageUrls" :key="imageUrl" class="inline-block mr-2">
                  <img :src="imageUrl" alt="Billede" class="w-48 h-48 object-cover rounded-lg cursor-pointer" @click="openModal(imageUrl)" />
                </div>
              </div>
              <h3 class="text-xl font-bold">{{ jobSeeker.name }}</h3>
              <p class="text-gray-700 mt-2">{{ jobSeeker.description }}</p>
              <p class="text-gray-500 mt-1">{{ jobSeeker.location }}</p>
            </div>
          </div>
          <p v-else class="text-center text-gray-500">Ingen jobsøgere fundet</p>
        </div>

        <!-- Column for salon jobs -->
        <div>
          <h2 class="text-4xl font-semibold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500">
            Salon Jobs
          </h2>
          <div v-if="salonJobs.length > 0" class="grid grid-cols-1 gap-6">
            <div v-for="salonJob in salonJobs" :key="salonJob.id" class="bg-white shadow-lg rounded-3xl p-6 transform transition duration-300 hover:scale-105">
              <div v-if="salonJob.imageUrls" class="scrollable-images mb-4">
                <div v-for="imageUrl in salonJob.imageUrls" :key="imageUrl" class="inline-block mr-2">
                  <img :src="imageUrl" alt="Billede" class="w-48 h-48 object-cover rounded-lg cursor-pointer" @click="openModal(imageUrl)" />
                </div>
              </div>
              <h3 class="text-xl font-bold">{{ salonJob.salonName }}</h3>
              <p class="text-gray-700 mt-2">{{ salonJob.jobDescription }}</p>
              <p class="text-gray-500 mt-1">{{ salonJob.location }}</p>
            </div>
          </div>
          <p v-else class="text-center text-gray-500">Ingen salonjobs fundet</p>
        </div>
      </div>

      <!-- Image Modal -->
      <div v-if="showModal" class="fixed inset-0 flex justify-center items-center z-50 transition-opacity duration-500" :class="{ 'opacity-100': showModal, 'opacity-0': !showModal }">
        <div class="relative backdrop-blur-md bg-white bg-opacity-10 p-4 rounded-lg shadow-lg transition-transform duration-500 transform scale-100" :class="{ 'scale-100': showModal, 'scale-95': !showModal }">
          <img :src="modalImageUrl" class="max-w-full max-h-full rounded-lg" />
          <button @click="closeModal" class="absolute top-0 right-0 text-white text-4xl p-2 bg-red-500 rounded-full hover:bg-red-600 transition">&times;</button>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { storage, db } from '@/firebase';
import BaseLayout from '@/components/BaseLayout.vue';

export default {
  metaInfo() {
    return {
      title: 'Book de Bedste Frisører og Neglesaloner i København | Fade, Herreklip, Gele- og Akrylnegle',
      meta: [
        {
          name: 'description',
          content: 'Find og book tid hos de frisører og neglesaloner i København. Specialister i Fade, Herreklip, Dameklip, Gele- og Akrylnegle. Enkel booking, eksklusiv service'
        }
      ]
    };
  },
  components: {
    BaseLayout
  },
  data() {
    return {
      postingType: 'seeker',
      jobSeeker: {
        name: '',
        age: '',
        location: '',
        description: '',
        imageUrls: []
      },
      jobPoster: {
        salonName: '',
        location: '',
        jobDescription: '',
        contactEmail: '',
        imageUrls: []
      },
      selectedFiles: null,
      jobSeekers: [],
      salonJobs: [],
      showModal: false,
      modalImageUrl: null
    };
  },
  methods: {
    onFileChange(event) {
      this.selectedFiles = event.target.files;
    },
    async uploadImages() {
      const urls = [];
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        const storageRef = ref(storage, `images/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        urls.push(downloadUrl);
      }
      return urls;
    },
    async submitPost() {
      if (this.selectedFiles) {
        const imageUrls = await this.uploadImages();
        if (this.postingType === 'seeker') {
          this.jobSeeker.imageUrls = imageUrls;
          await addDoc(collection(db, 'job_seekers'), this.jobSeeker);
        } else {
          this.jobPoster.imageUrls = imageUrls;
          await addDoc(collection(db, 'salon_jobs'), this.jobPoster);
        }
      }
      alert('Opslag indsendt succesfuldt!');
      this.clearForm();
      this.fetchListings();
    },
    clearForm() {
      this.jobSeeker = { name: '', age: '', location: '', description: '', imageUrls: [] };
      this.jobPoster = { salonName: '', location: '', jobDescription: '', contactEmail: '', imageUrls: [] };
      this.selectedFiles = null;
    },
    async fetchListings() {
      const salonJobSnapshot = await getDocs(collection(db, 'salon_jobs'));
      this.salonJobs = salonJobSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const jobSeekerSnapshot = await getDocs(collection(db, 'job_seekers'));
      this.jobSeekers = jobSeekerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    },
    openModal(imageUrl) {
      this.modalImageUrl = imageUrl;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.modalImageUrl = null;
    }
  },
  async created() {
    this.fetchListings();
  }
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.toggle-posting-type {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

button {
  transition: all 0.3s ease;
}

.grid {
  grid-gap: 1.5rem;
}

.scrollable-images {
  overflow-x: auto;
  white-space: nowrap;
}

.backdrop-blur-md {
  backdrop-filter: blur(10px);
}

.fixed {
  position: fixed;
  z-index: 50;
}

.bg-opacity-75 {
  background-color: black;
  opacity: 0.75;
}
</style>
