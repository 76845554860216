<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    // Udløser en event når siden er fuldt indlæst og klar til prerendering
    document.dispatchEvent(new Event('render-event'));
  }
};
</script>

<style scoped>
/* Custom styles (if needed) */
</style>
