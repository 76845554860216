<template>
  <BaseLayout>
    <div class="text-center">
      <h2 class="text-4xl font-semibold my-8 text-black">Kontakt os</h2>
      <p class="text-lg text-gray-700">Har du spørgsmål? Kontakt os ved hjælp af formularen nedenfor.</p>
      <form class="mt-8 space-y-4 bg-white bg-opacity-60 backdrop-filter backdrop-blur-3xl p-6 rounded-3xl shadow-2xl">
        <input type="text" placeholder="Dit navn" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" />
        <input type="email" placeholder="Din e-mail" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" />
        <textarea placeholder="Din besked" class="w-full p-3 border border-gray-300 rounded-full text-gray-900"></textarea>
        <button class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full transition duration-300">Send besked</button>
      </form>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '../components/BaseLayout.vue';

export default {
  metaInfo() {
    return {
      title: 'Kontakt os - Freshcuts',
      meta: [
        { name: 'description', content: 'Har du spørgsmål? Kontakt Freshcuts for yderligere information om vores tjenester.' }
      ]
    };
  },
  name: 'ContactPage',
  components: {
    BaseLayout,
  },
}
</script>
