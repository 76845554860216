// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Import Firebase Storage

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDC1XahlkWvsuTUCfdvOUcEaERQnP5CC_U",
  authDomain: "freshcuts-75bb7.firebaseapp.com",
  projectId: "freshcuts-75bb7",
  storageBucket: "freshcuts-75bb7.appspot.com", // Storage bucket is needed
  messagingSenderId: "851220045230",
  appId: "1:851220045230:web:d03fc230f06d24b913fc3f",
  measurementId: "G-8TY5TNQ1DN"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { db, auth, storage };
