<template>
  <BaseLayout>
    <div class="container mx-auto p-6">
      <h2 class="text-4xl font-semibold mb-6 text-center text-gray-900">Dashboard</h2>
      <p class="text-xl text-center mb-12 text-gray-600">Velkommen, {{ salonName }}</p>

      <!-- Online Booking Status -->
      <div v-if="salon" class="mb-6"> <!-- Tilføjet v-if til at sikre, at salon-data er indlæst -->
        <p class="text-lg font-semibold mb-2">
          Online Booking Status:
          <span
            :class="{ 'text-green-600': salon.isOnlineBookingEnabled, 'text-red-600': !salon.isOnlineBookingEnabled }">
            {{ salon.isOnlineBookingEnabled ? 'Aktiveret' : 'Deaktiveret' }}
          </span>
        </p>

        <!-- Aktivér/Deaktivér knap -->
        <button @click="toggleBooking(salon.id)" :class="{
          'bg-green-500 hover:bg-green-600': salon.isOnlineBookingEnabled,
          'bg-red-500 hover:bg-red-600': !salon.isOnlineBookingEnabled
        }" class="py-2 px-6 rounded-full text-white font-bold shadow-md transition-all">
          {{ salon.isOnlineBookingEnabled ? 'Deaktiver Online Booking' : 'Aktivér Online Booking' }}
        </button>
      </div>


      <!-- Billede Upload og Rækkefølgehåndtering -->
      <div v-if="salon" class="mb-6">
        <h3 class="text-2xl font-semibold mb-4">Administrer dine billeder</h3>

        <div v-if="salon.images && salon.images.length > 0">
          <div v-for="(image, index) in salon.images" :key="index" class="flex items-center mb-4">
            <!-- Tilføj rækkefølgenummer -->
            <span class="mr-4 text-lg font-bold">{{ index + 1 }}.</span>
            <img :src="image" class="w-32 h-32 object-cover rounded-lg shadow-md" alt="Salon billede" />
            <div class="ml-4">
              <button @click="moveImage(index, -1)" :disabled="index === 0"
                class="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-full mr-2">Op</button>
              <button @click="moveImage(index, 1)" :disabled="index === salon.images.length - 1"
                class="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-full">Ned</button>
              <button @click="deleteImage(index)"
                class="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded-full ml-2">Slet</button>
            </div>
          </div>
        </div>

        <!-- Billede Upload -->
        <div class="mt-6">
          <input type="file" @change="uploadImage" accept="image/*" />
        </div>
      </div>


      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <!-- Tidslinje for dagens bookinger -->
        <div class="col-span-1 md:col-span-2 lg:col-span-3 bg-white p-4 rounded-xl shadow-sm">
          <h3 class="text-xl font-medium mb-4 text-gray-800">Dagens Bookinger</h3>
          <div v-if="loading" class="text-center text-gray-500">Indlæser...</div>
          <div v-else-if="todayBookings.length === 0" class="text-center text-gray-500">
            Ingen bookinger i dag
          </div>
          <div v-else>
            <!-- Tidslinje komponent -->
            <div class="relative">
              <div class="absolute h-full border border-gray-200 left-10"></div>
              <ul class="space-y-6">
                <li v-for="booking in todayBookings" :key="booking.id" class="relative pl-14">
                  <!-- Tidspunkt -->
                  <div
                    class="absolute left-0 flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 text-blue-500">
                    {{ booking.time }}
                  </div>
                  <!-- Booking Info -->
                  <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
                    <p class="text-base font-medium text-gray-700">{{ booking.name }}</p>
                    <p class="text-sm text-gray-500">{{ booking.service }}</p>
                    <p class="text-sm text-gray-400">{{ booking.notes }}</p>
                    <!-- Vis kun knapperne, hvis status er "pending" -->
                    <div v-if="booking.status === 'pending'" class="flex space-x-2 mt-2">
                      <button @click="approveBooking(booking)"
                        class="bg-blue-500 text-white text-sm px-3 py-1 rounded-full">Godkend</button>
                      <button @click="deleteBooking(booking.id, booking)"
                        class="bg-red-500 text-white text-sm px-3 py-1 rounded-full">Slet</button>
                    </div>
                    <div v-else-if="booking.status === 'approved'" class="text-green-500 text-sm mt-2">
                      Bookingen er godkendt
                    </div>
                    <div v-else-if="booking.status === 'deleted'" class="text-red-500 text-sm mt-2">
                      Bookingen er slettet
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Kommende Bookinger -->
        <div class="bg-white p-4 rounded-xl shadow-sm">
          <h3 class="text-xl font-medium mb-4 text-gray-800">Kommende Bookinger</h3>
          <div v-if="loading" class="text-center text-gray-500">Indlæser...</div>
          <div v-else-if="upcomingBookings.length === 0" class="text-center text-gray-500">
            Ingen kommende bookinger
          </div>
          <div v-else>
            <ul class="space-y-6">
              <li v-for="booking in upcomingBookings" :key="booking.id" class="relative pl-14">
                <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
                  <p class="text-base font-medium text-gray-700">{{ booking.name }}</p>
                  <p class="text-sm text-gray-500">{{ booking.service }}</p>
                  <p class="text-sm text-gray-400">{{ booking.date }} {{ booking.time }}</p>
                  <p class="text-sm text-gray-400">{{ booking.notes }}</p>
                  <!-- Vis kun knapperne, hvis status er "pending" -->
                  <div v-if="booking.status === 'pending'" class="flex space-x-2 mt-2">
                    <button @click="approveBooking(booking)"
                      class="bg-blue-500 text-white text-sm px-3 py-1 rounded-full">Godkend</button>
                    <button @click="deleteBooking(booking.id, booking)"
                      class="bg-red-500 text-white text-sm px-3 py-1 rounded-full">Slet</button>
                  </div>
                  <div v-else-if="booking.status === 'approved'" class="text-green-500 text-sm mt-2">
                    Bookingen er godkendt
                  </div>
                  <div v-else-if="booking.status === 'deleted'" class="text-red-500 text-sm mt-2">
                    Bookingen er slettet
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Kundeoplysninger -->
        <div class="bg-white p-4 rounded-xl shadow-sm">
          <h3 class="text-xl font-medium mb-4 text-gray-800">Kundeoplysninger</h3>
          <div v-if="loading" class="text-center text-gray-500">Indlæser...</div>
          <div v-else-if="customerInfo.length === 0" class="text-center text-gray-500">Ingen kundeoplysninger</div>
          <ul v-else class="space-y-6">
            <li v-for="customer in customerInfo" :key="customer.email" class="relative">
              <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p class="text-base font-medium text-gray-700">{{ customer.name }}</p>
                <p class="text-sm text-gray-500">{{ customer.phone }}</p>
                <p class="text-sm text-gray-400">{{ customer.email }}</p>
              </div>
            </li>
          </ul>
        </div>

        <!-- Månedlige Indtægter -->
        <div class="bg-white p-4 rounded-xl shadow-sm col-span-1 md:col-span-2 lg:col-span-3">
          <h3 class="text-xl font-medium mb-4 text-gray-800">Månedlige Indtægter</h3>
          <div v-if="loading" class="text-center text-gray-500">Indlæser...</div>
          <p v-else class="text-lg text-gray-700">{{ monthlyEarnings }} kr</p>
        </div>

        <!-- Alle Bookings Over 12 Måneder -->
        <div class="bg-white p-4 rounded-xl shadow-sm col-span-1 md:col-span-2 lg:col-span-3">
          <h3 class="text-xl font-medium mb-4 text-gray-800">Alle Bookings Over 12 Måneder</h3>
          <div v-if="loading" class="text-center text-gray-500">Indlæser...</div>
          <ul v-else class="space-y-6">
            <li v-for="booking in allBookings" :key="booking.id" class="relative">
              <div class="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p class="text-base font-medium text-gray-700">{{ booking.date }}</p>
                <p class="text-sm text-gray-500">{{ booking.name }}</p>
                <p class="text-sm text-gray-400">{{ booking.service }}</p>
                <p class="text-sm text-gray-400">{{ booking.notes }}</p>
                <!-- Vis kun knapperne, hvis status er "pending" -->
                <div v-if="booking.status === 'pending'" class="flex space-x-2 mt-2">
                  <button @click="approveBooking(booking)"
                    class="bg-blue-500 text-white text-sm px-3 py-1 rounded-full">Godkend</button>
                  <button @click="deleteBooking(booking.id, booking)"
                    class="bg-red-500 text-white text-sm px-3 py-1 rounded-full">Slet</button>
                </div>
                <div v-else-if="booking.status === 'approved'" class="text-green-500 text-sm mt-2">
                  Bookingen er godkendt
                </div>
                <div v-else-if="booking.status === 'deleted'" class="text-red-500 text-sm mt-2">
                  Bookingen er slettet
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>


<script>
import BaseLayout from '../components/BaseLayout.vue';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, doc, updateDoc, arrayUnion, getDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import emailjs from '@emailjs/browser';

// Initialiser Firestore
const db = getFirestore();


export default {
  metaInfo() {
    return {
      isOnlineBookingEnabled: false, // Holder styr på booking-status
    };
  },
  name: 'DashboardPage',
  components: {
    BaseLayout,
  },
  data() {
    return {
      loading: true,
      todayBookings: [],
      upcomingBookings: [],
      customerInfo: [],
      monthlyEarnings: 0,
      allBookings: [],
      salonName: '',
      salon: { // Tilføjer en tom struktur for salon
        isOnlineBookingEnabled: false, // initialiserer som falsk
      },
    };
  },
  async created() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      await this.fetchSalonName(user.uid);
      await this.fetchBookings(user.uid);
    }
  },
  methods: {
    async fetchSalonName(userId) {
      const userDoc = doc(db, 'salons', userId); // Brug salon-kollektionen
      const userSnap = await getDoc(userDoc);
      if (userSnap.exists()) {
        this.salon = userSnap.data();

        // Hvis "isOnlineBookingEnabled" ikke findes, initialiser det til false
        if (this.salon.isOnlineBookingEnabled === undefined) {
          await updateDoc(userDoc, { isOnlineBookingEnabled: false });
          this.salon.isOnlineBookingEnabled = false; // Opdater lokalt
        }
      } else {
        console.error("No such document!");
      }
    }
    ,
    async toggleBooking() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const salonRef = doc(db, 'salons', user.uid);  // Opdater den salon, der er logget ind
        const newStatus = !this.salon.isOnlineBookingEnabled;  // Skift status
        await updateDoc(salonRef, {
          isOnlineBookingEnabled: newStatus,  // Opdater Firestore
        });
        this.salon.isOnlineBookingEnabled = newStatus;  // Opdater lokalt
        alert(`Online booking er nu ${newStatus ? 'aktiveret' : 'deaktiveret'}`);
      }
    }
    ,
    async fetchBookings(userId) {
      this.loading = true; // Start med loading-true
      const today = new Date().toISOString().split('T')[0];
      const bookingsRef = collection(db, 'bookings');
      const q = query(bookingsRef, where('salonId', '==', userId));
      const bookingsSnapshot = await getDocs(q);
      const bookings = bookingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      this.todayBookings = bookings.filter(booking => booking.date === today);
      this.upcomingBookings = bookings.filter(booking => booking.date > today);
      this.customerInfo = bookings.map(booking => ({
        name: booking.name,
        phone: booking.phone,
        email: booking.email,
      }));
      this.allBookings = bookings;
      this.monthlyEarnings = bookings.reduce((total, booking) => total + parseInt(booking.price || 0), 0);

      this.loading = false; // Sørg for at sætte loading-tilbage til false
    }
    ,
    async sendEmail(to, toName, service, date, time, templateId) {
      try {
        const templateParams = {
          to_name: toName,
          service: service,
          date: date,
          time: time,
          salon_name: this.salonName,
          to_email: to,
        };

        await emailjs.send('service_ehb98xm', templateId, templateParams, 'f8drQdDrH5AfEidz-');
        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    },
    async approveBooking(booking) {
      try {
        const bookingRef = doc(db, 'bookings', booking.id);
        await updateDoc(bookingRef, {
          status: 'approved'
        });
        await this.sendEmail(booking.email, booking.name, booking.service, booking.date, booking.time, 'template_9tnba2y');
        alert('Booking godkendt');
        await this.fetchBookings(getAuth().currentUser.uid);  // Refresh bookings list
      } catch (error) {
        console.error("Error approving booking: ", error);
      }
    },
    async deleteBooking(bookingId, booking) {
      try {
        const bookingRef = doc(db, 'bookings', bookingId);
        await deleteDoc(bookingRef);
        await this.sendEmail(booking.email, booking.name, booking.service, booking.date, booking.time, 'template_k881syh');
        alert('Booking slettet');
        await this.fetchBookings(getAuth().currentUser.uid);  // Refresh bookings list
      } catch (error) {
        console.error("Error deleting booking: ", error);
      }
    },
    async uploadImage(event) {
      try {
        const file = event.target.files[0];
        if (!file) return;

        const auth = getAuth();
        const user = auth.currentUser;  // Get the logged-in user
        if (!user) {
          throw new Error('User is not authenticated.');
        }

        const storage = getStorage();  // Firebase Storage instance
        const fileRef = ref(storage, `salon_images/${user.uid}/${file.name}`);  // Use UID
        await uploadBytes(fileRef, file);  // Upload file

        const downloadURL = await getDownloadURL(fileRef);  // Get download URL

        // Add the new image to Firestore
        await updateDoc(doc(db, 'salons', user.uid), {
          images: arrayUnion(downloadURL)  // Use arrayUnion to add the image
        });

        // Update the local images array
        this.salon.images.push(downloadURL);
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Something went wrong while uploading the image.');
      }
    },
    async moveImage(index, direction) {
      const newIndex = index + direction;
      if (newIndex < 0 || newIndex >= this.salon.images.length) return;  // Avoid moving out of bounds

      // Swap images in the array
      const movedImage = this.salon.images.splice(index, 1)[0];
      this.salon.images.splice(newIndex, 0, movedImage);

      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User is not authenticated.');
      }

      // Update Firestore with the new order
      await updateDoc(doc(db, 'salons', user.uid), {
        images: this.salon.images
      });
    }
    ,
    async deleteImage(index) {
      try {
        const imageToDelete = this.salon.images[index];

        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User is not authenticated.');
        }

        const storage = getStorage();  // Get Firebase Storage instance
        const fileRef = ref(storage, imageToDelete);  // Reference to the file
        await deleteObject(fileRef);  // Delete the image from Firebase Storage

        // Remove the image from Firestore
        this.salon.images.splice(index, 1);
        await updateDoc(doc(db, 'salons', user.uid), {
          images: this.salon.images
        });

        alert('Image deleted successfully!');
      } catch (error) {
        console.error('Error deleting image:', error);
        alert('Something went wrong while deleting the image.');
      }
    }


  },
};
</script>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.shadow-2xl {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
}

.bg-opacity-60 {
  background-color: rgba(255, 255, 255, 0.6);
}

.backdrop-filter {
  backdrop-filter: blur(25px);
}

.bg-green-500 {
  background-color: #10b981;
  /* Grøn farve til aktiveret */
}

.hover\:bg-green-600:hover {
  background-color: #059669;
  /* Mørkere grøn ved hover */
}

.bg-gray-500 {
  background-color: #6b7280;
  /* Grå farve til deaktiveret */
}

.hover\:bg-gray-600:hover {
  background-color: #4b5563;
  /* Mørkere grå ved hover */
}

.text-white {
  color: white;
}

.rounded-full {
  border-radius: 9999px;
  /* Gør knapperne runde */
}

.font-bold {
  font-weight: 700;
}

.transition {
  transition: all 0.3s ease;
}

.shadow-md {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Let skygge for at tilføje dybde */
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-gray-800 {
  color: #1f2937;
  /* Neutral tekstfarve */
}
</style>
