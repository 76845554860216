<template>
  <div v-if="showPopup" @click.self="closePopup" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 popup-background">
    <transition name="puff">
      <div v-show="showPopup" class="relative bg-white p-8 rounded-lg shadow-lg w-full max-w-md md:max-w-lg lg:max-w-xl text-center">
        <!-- Krydsknappen -->
        <button @click="closePopup" class="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl">
          &#10005;
        </button>

        <h2 class="text-3xl font-extrabold mb-6">🚀 Bliv Partner</h2>
        <p class="text-xl font-semibold text-gray-700 mb-4">Pris: <span class="text-orange-600">99,- / md.</span></p>

        <!-- Salgspunkter i kort format -->
        <ul class="list-none text-left text-lg mb-6 space-y-6">
          <li class="flex items-start space-x-2">
            <span>💼</span>
            <span>Få flere kunder hver måned!</span>
          </li>
          <li class="flex items-start space-x-2">
            <span>🤝</span>
            <span>Få din egen profil på platformen.</span>
          </li>
          <li class="flex items-start space-x-2">
            <span>📅</span>
            <span>Nem og hurtig online booking.</span>
          </li>
        </ul>

        <a href="https://www.freshcuts.dk/partner" target="_blank" class="cta-button">Bliv Partner Nu</a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: true // Display popup by default
    };
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    }
  }
};
</script>

<style scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.popup-background {
  cursor: pointer; /* Sørger for at baggrunden er klikbar */
}

.popup-background > div {
  cursor: default; /* Sørger for at pop-up indhold ikke er klikbart */
}

.bg-white {
  background-color: white;
}

.rounded-lg {
  border-radius: 1rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.cta-button {
  display: inline-block;
  background-color: #ff5722;
  color: white;
  padding: 12px 24px;
  border-radius: 50px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #e64a19;
}

.cta-button:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(90deg, rgba(255, 87, 34, 0) 0%, rgba(255, 87, 34, 1) 50%, rgba(255, 87, 34, 0) 100%);
  border-radius: 50px;
  animation: infinite-flow 2s infinite linear;
}

@keyframes infinite-flow {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Puff animation */
@keyframes puff {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.puff-enter-active,
.puff-leave-active {
  transition: all 0.5s ease;
}

.puff-leave-active {
  animation: puff 0.5s forwards;
}

/* Responsiv styling */
.w-full {
  width: 100%;
}

.max-w-md {
  max-width: 28rem; /* Perfekt til iPhone */
}

@media (min-width: 768px) {
  .md\:max-w-lg {
    max-width: 32rem; /* Passer til tablets */
  }
}

@media (min-width: 1024px) {
  .lg\:max-w-xl {
    max-width: 36rem; /* Passer til større skærme */
  }
}
</style>
