<template>
  <BaseLayout>
    <div class="flex justify-center items-center min-h-screen">
      <div class="bg-white bg-opacity-60 backdrop-filter backdrop-blur-3xl p-8 rounded-3xl shadow-2xl text-gray-900 w-full max-w-md">
        <h2 class="text-4xl font-semibold mb-8 text-center">Login</h2>
        <form @submit.prevent="login" class="space-y-6">
          <div>
            <label for="email" class="block text-lg">Email</label>
            <input type="email" id="email" v-model="email" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
          </div>
          <div>
            <label for="password" class="block text-lg">Password</label>
            <input type="password" id="password" v-model="password" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
          </div>
          <button type="submit" class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition duration-300">Login</button>
        </form>
        <p class="mt-6 text-center">Har du ikke en konto? <router-link to="/partner" class="text-blue-500 hover:underline">Opret en konto</router-link></p>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import BaseLayout from '../components/BaseLayout.vue';

export default {
  name: 'LoginPage',
  metaInfo() {
    return {
      title: 'Salon Jobs - Freshcuts',
      meta: [
        { name: 'description', content: 'Find de nyeste jobopslag for salonarbejde her.' }
      ]
    };
  },
  components: {
    BaseLayout,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    async login() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push('/dashboard');
      } catch (error) {
        console.error("Error logging in: ", error);
      }
    }
  }
};
</script>
